import { useState, useContext, useEffect, createContext } from 'react'
import { ThemeProvider as ThemeStyled } from 'styled-components'
import getTheme from 'config/theme'

const ThemeContext = createContext()

function ThemeProvider({ children, version = `v1` }) {
  const [theme, setTheme] = useState(getTheme({}, version))
  function mergeTheme(theme) {
    setTheme(getTheme(theme))
  }
  useEffect(() => {
    //Seteo de favicon del tema
    const link =
      document.querySelector(`link[rel*='icon']`) ||
      document.createElement(`link`)
    link.type = `image/x-icon`
    link.rel = `shortcut icon`
    link.href = theme.favicon
    document.getElementsByTagName(`head`)[0].appendChild(link)
  }, [theme.favicon])
  return (
    <ThemeContext.Provider value={{ theme, mergeTheme }}>
      <ThemeStyled theme={theme}>{children}</ThemeStyled>
    </ThemeContext.Provider>
  )
}

function useTheme() {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`)
  }
  return context
}

export { ThemeProvider, useTheme }
