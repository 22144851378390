/* eslint-disable complexity */
import { useState, useEffect, useCallback } from 'react'
import { regexValidator } from 'utils'
import { clean, validate as rutValidator } from 'rut.js'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

/**
 * stateSchema = {
 *  field: defaultValue,
 * ...
 * }
 *
 * validationSchema = {
 *  field: {
 *    isActive: boolean,
 *    isRequired: boolean,
 *    error: string,
 *    validator?: {
 *      regEx: expresion regular para evaluar campo,
 *      error: string,
 *    }
 *  }
 * }
 *
 * callback: Función de confirmación del formulario
 */

function getInitialState(stateSchema) {
  const errors = {}
  Object.keys(stateSchema).forEach((key) => (errors[key] = null))
  return { values: stateSchema, errors }
}

function useForm({
  stateSchema,
  validationSchema = {},
  callback,
  sideEffect = {},
  extraProps,
}) {
  const [state, setState] = useState(getInitialState(stateSchema))
  const [canSubmit, setCanSubmit] = useState(false)
  const { values, errors } = state

  // Autocompletado
  const {
    suggestions: addressSuggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { componentRestrictions: { country: `cl` } },
    debounce: 300,
  })

  async function addressSelect({ description, structured_formatting: data }) {
    setValue(description, false)
    clearSuggestions()
    try {
      const geoCode = await getGeocode({ address: description })
      const { lat, lng } = await getLatLng(geoCode[0])
      const streetNumber = data.main_text.match(/\d+/g)
      const communeString = data.secondary_text.split(`, `)
      const streetData = {}
      if (streetNumber) {
        streetData.street = data.main_text.replace(` ${streetNumber[0]}`, ``)
        streetData.streetNumber = streetNumber[0]
      }
      setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          address: description,
          ...streetData,
          communeString,
          latitude: lat,
          longitude: lng,
        },
      }))
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: `Se ha producido un error al ingresar la dirección`,
        },
      }))
    }
  }

  // useEffect custom
  const condition = values[sideEffect.condition]
  useEffect(() => {
    if (condition && sideEffect.fn) {
      sideEffect.fn(condition)
    }
  }, [condition])

  // effect especifico para validacion de comunas
  const communes = extraProps ? extraProps.communes : undefined
  useEffect(() => {
    if (communes && validationSchema.communeId && values.communeId) {
      const belongsToCommunes = extraProps.communes.some(
        (commune) => commune.id === values.communeId,
      )
      setState((prevState) => ({
        values: {
          ...prevState.values,
          communeId: belongsToCommunes ? values.communeId : null,
        },
        errors: {
          ...prevState.errors,
          communeId: belongsToCommunes
            ? null
            : validationSchema.communeId.error,
        },
      }))
    }
  }, [communes])

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // eslint-disable-next-line max-len
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const hasErrorInState = useCallback(() => {
    let input = null
    const hasError = Object.keys(validationSchema).some((key) => {
      const { isRequired, isActive = true } = validationSchema[key]
      const value = values[key]
      const error = errors[key]
      input = key
      return (
        (isActive &&
          isRequired &&
          (value === null || value === undefined || value === ``)) ||
        error
      )
    })
    return hasError && input
  }, [values, errors, validationSchema])

  // Evalua si se puede enviar el formulario
  useEffect(() => {
    setCanSubmit(!hasErrorInState())
  }, [values, errors, validationSchema])

  // Used to handle every changes in every input
  const handleChange = useCallback(
    (value, name) => {
      let error = ``
      if (validationSchema[name]) {
        if (validationSchema[name].type === `address`) {
          setValue(value)
        }
        if (
          validationSchema[name].isRequired &&
          (value === null || value === undefined || value === ``)
        ) {
          error = validationSchema[name].error
        }

        if (
          validationSchema[name].validator !== null &&
          typeof validationSchema[name].validator === `object`
        ) {
          if (value && !validationSchema[name].validator.regEx.test(value)) {
            error = validationSchema[name].validator.error
          }
        } else if (value) {
          //validadores por defecto
          if (
            [`email`, `contactMail`].includes(name) &&
            !regexValidator(value, `email`)
          ) {
            error = validationSchema[name].error
          }

          if (
            name === `phone` &&
            value !== `` &&
            !regexValidator(value, `phone`)
          ) {
            error = validationSchema[name].error
          }
          if (name === `rut` && value !== `` && !rutValidator(clean(value))) {
            error = validationSchema[name].error
          }
        }
      }
      if (error) {
        setCanSubmit(false)
      } else {
        setCanSubmit(true)
      }
      setState((prevState) => ({
        values: {
          ...prevState.values,
          [name]: value,
        },
        errors: {
          ...prevState.errors,
          [name]: error,
        },
      }))
    },
    [validationSchema],
  )

  const handleSubmit = useCallback(() => {
    const inputWithError = hasErrorInState()
    if (inputWithError) {
      const { error } = validationSchema[inputWithError]
      setState((prevState) => ({
        values: prevState.values,
        errors: {
          ...prevState.errors,
          [inputWithError]: error,
        },
      }))
    } else if (Object.entries(values).length > 0) {
      callback(values)
    }
  }, [values, errors, extraProps])

  return {
    values,
    errors,
    canSubmit,
    handleChange,
    handleSubmit,
    addressSuggestions,
    clearSuggestions,
    addressSelect,
    setter: setState,
  }
}

export default useForm
