import { FC } from 'react'
import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  position,
  SpaceProps,
  LayoutProps,
  PositionProps,
} from 'styled-system'

interface BaseImgProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, `width` | `height`>,
    SpaceProps,
    LayoutProps,
    PositionProps {}
const Img: FC<BaseImgProps> = styled.img<BaseImgProps>`
  ${compose(space, layout, position)}
`
export default Img
