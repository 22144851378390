import { useEffect } from 'react'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'
import { validationModes } from 'config/constants'
export default function useValidator({
  schema,
  mode = validationModes.onSubmit,
  defaultValues = null,
}) {
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode,
    resolver: joiResolver(schema),
  })
  useEffect(() => {
    if (defaultValues != null) {
      reset(defaultValues)
    }
  }, [])
  return {
    handleSubmit,
    setValue,
    watch,
    register,
    control,
    errors,
    reset,
  }
}
