/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { FC } from 'react'
import { compose, system, layout, LayoutProps,
  ColorProps,
  SpaceProps,
  BorderProps,
  TypographyProps} from 'styled-system'
import AntTable from 'antd/es/table'
import { getColorWithOpacity } from 'utils/styled'
import { TableProps } from 'antd/es/table'
import 'antd/es/table/style/index.css'
import 'antd/es/pagination/style/index.css'

interface TableExtendedProps<T>
  extends TableProps<T>,
    Omit<LayoutProps, `size`>,
    ColorProps,
    SpaceProps,
    BorderProps,
    TypographyProps {
  headerBackgroundColor?: ColorProps[`backgroundColor`]
  headerWeight?: TypographyProps[`fontWeight`]
  headerFontSize?: TypographyProps[`fontSize`]
  headerColor?: ColorProps[`color`]
  headerPadding?: SpaceProps[`padding`]
  lastRowWeight?: TypographyProps[`fontWeight`]
  dividerMiddle?: BorderProps[`borderRight`]
  oddBackgroundColor?: ColorProps[`backgroundColor`]
}

function CustomTableWrapper<T>(props: TableExtendedProps<T>): JSX.Element {
  const CustomTable: FC<TableExtendedProps<T>> = styled(AntTable)<
  TableExtendedProps<T>>
  `
  width: 100% !important;
  ${layout}
  .ant-table-thead > tr > th {
    ${compose(
  system({
    border: {
      property: `border`,
    },
    padding: {
      property: `padding`
    },
    headerBackgroundColor: {
      property: `backgroundColor`,
      scale: `colors`,
      transform: getColorWithOpacity,
    },
    headerWeight: {
      property: `fontWeight`,
      scale: `fontWeights`,
    },
    headerFontSize: {
      property: `fontSize`,
      scale: `fontSizes`,
    },
    headerColor: {
      property: `color`,
      scale: `colors`,
    },
    headerPadding: {
      property: `padding`,
    },
    headerFontWeight: {
      property: `fontWeight`,
      scale: `fontWeights`,
    },
  }),
)}
  }
  & .ant-table-tbody tr:last-child > td {
    ${compose(
  system({
    lastRowWeight: {
      property: `fontWeight`,
      scale: `fontWeights`,
    },
  }),
)}
    & > h1 {
      ${compose(
  system({
    lastRowWeight: {
      property: `fontWeight`,
      scale: `fontWeights`,
    },
  }),
)}
    }
  }
  & .ant-table-tbody tr > td {
    ${compose(
  system({
    padding: {
      property: `padding`
    },
    border: {
      property: `border`
    },
    dividerMiddle: {
      property: `borderRight`,
    },
  }),
)}
  }
  & .ant-table-tbody tr > td {
    vertical-align: middle;
    ${compose(
  system({
    backgroundColor: {
      property: `backgroundColor`,
      scale: `colors`,
    },
    fontWeight: {
      property: `fontWeight`,
      scale: `fontWeights`,
    },
    fontSize: {
      property: `fontSize`,
      scale: `fontSizes`,
    },
    color: {
      property: `color`,
      scale: `colors`,
    },
    padding: {
      property: `padding`,
    },
  }),
)}
  }
  & .ant-table-tbody tr:nth-child(even) {
    ${compose(
  system({
    oddBorderBottom: {
      property: `border`,
    },
    oddBackgroundColor: {
      property: `backgroundColor`,
      scale: `colors`,
    },
  }),
)}
  }
  & .ant-table {
    width: 100%;
    ${compose(
  system({
    border: {
      property: `border`,
    },
    borderColor: {
      property: `borderColor`,
      scale: `colors`,
    },
  }),
)}
  }
`
CustomTable.defaultProps = {
  headerColor: `gray.6`,
  headerWeight: `normal`,
  headerBackgroundColor: `white`,
  oddBackgroundColor: `white`,
  fontWeight: `semi-bold`,
  fontSize: `16px`,
  headerFontSize: `16px`,
  padding: `5px`,
  border: `none`,
  borderColor: `transparent`,
  borderWidth: `0px`,
  lastRowWeight: `1`,
  dividerMiddle: `none`,
}
return <CustomTable {...props}/>

}

export default CustomTableWrapper
