import styled from 'styled-components'
import { colorsWithOpacity } from 'utils'
import { color } from 'styled-system'
const CalendarContainer = styled.div`
  width: 100%;
  & .rbc-time-view .rbc-allday-cell,
  & .rbc-day-slot .rbc-event-label {
    display: none;
  }
  & .rbc-header,
  & .rbc-time-view,
  & .rbc-timeslot-group,
  & .rbc-event,
  & .rbc-time-header-content,
  & .rbc-time-header-gutter,
  & .rbc-time-content,
  & .rbc-events-container,
  & .rbc-time-view-resources .rbc-time-gutter,
  & .rbc-day-slot .rbc-time-slot {
    border-width: 0 !important;
  }
  .rbc-timeslot-group:not(:first-child) .rbc-time-slot {
    position: relative;
    top: -10px;
  }
  & .rbc-day-slot .rbc-timeslot-group {
    border-top-width: 1px !important;
    border-top-style: solid !important;
    ${colorsWithOpacity}
  }
  & .rbc-day-slot .rbc-events-container {
    margin: 0;
    border-right-width: 1px !important;
    border-right-style: solid !important;
    ${colorsWithOpacity}
  }
  & .rbc-today {
    background-color: transparent;
  }
  & .rbc-event,
  & .rbc-event.rbc-selected {
    background-color: transparent;
    border-radius: 0;
    left: 0 !important;
    width: 100% !important;
    padding: 1px;
  }
  & .rbc-event-content {
    display: flex;
  }
  & .rbc-timeslot-group {
    min-height: 180px;
  }
  & .rbc-current-time-indicator {
    height: 2px;
    background-color: red;
  }
  & .rbc-time-view-resources .rbc-day-slot,
  & .rbc-time-view-resources .rbc-time-header-content {
    min-width: 25vw;
  }
  & .rbc-header {
    padding: 1em;
    font-size: 1em;
    font-weight: bold;
  }
  & span.rbc-label,
  & .rbc-header {
    ${color}
  }
`
CalendarContainer.defaultProps = {
  borderColor: `gray.2@0.6`,
  color: `primary.2`,
}

export { CalendarContainer }
