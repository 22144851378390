import { normalizeString } from './format'

export function normalizeUppercase(text) {
  return normalizeString(`${text}`.toUpperCase())
}

export const filterTable = ({ data, filterText, filters }) => {
  if (filterText === ``) {
    return data
  }
  return data.filter((row) => {
    return filters.find((filter) => {
      const nesting = filter.split(`.`)
      let auxRow = row
      nesting.forEach((nest) => {
        if (auxRow[nest]) {
          auxRow = auxRow[nest]
        }
      })
      return normalizeUppercase(auxRow).includes(normalizeUppercase(filterText))
    })
  })
}

export function removeIndexFromArray(array, index) {
  return array.filter((_, i) => i != index)
}

export function arrayMove(array, from, to) {
  const nextArray = [...array]
  const startIndex = from < 0 ? nextArray.length + from : from
  if (startIndex >= 0 && startIndex < nextArray.length) {
    const endIndex = to < 0 ? nextArray.length + to : to
    const [item] = nextArray.splice(from, 1)
    nextArray.splice(endIndex, 0, item)
  }
  return nextArray
}
