import { FC } from 'react'
import styled from 'styled-components'
import {
  compose,
  system,
  background,
  space,
  layout,
  color,
  border,
  typography,
  BackgroundProps,
  TypographyProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
} from 'styled-system'
import AntdRadio, { RadioGroupProps } from 'antd/es/radio'
import { CheckboxOptionType } from 'antd/es/checkbox'
import Container from '../Container'
import Typography from '../Typography'
interface BaseInputProps
  extends Omit<RadioGroupProps, `size`>,
    BackgroundProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    TypographyProps {
  checkColor?: string
  borderCheckColor?: string
}

const Radio: FC<BaseInputProps> = styled(AntdRadio.Group)<BaseInputProps>`
  & .ant-radio-checked .ant-radio-inner {
    ${system({
      borderCheckColor: {
        property: `borderColor`,
        scale: `colors`,
      },
    })}
  }
  & .ant-radio-inner::after {
    ${system({
      checkColor: {
        property: `backgroundColor`,
        scale: `colors`,
      },
    })}
  }
  &.ant-radio-group {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`

Radio.defaultProps = {
  checkColor: `primary.2`,
  borderCheckColor: `primary.2`,
}
interface InputProps extends Omit<BaseInputProps, `onChange`>, FlexboxProps {
  onChange(value: number, name: string): void
  value?: number
  error?: string
  label?: string
  options: Array<CheckboxOptionType>
}

const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  value,
  onChange,
  options = [],
  name,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Radio
          onChange={({ target: { value } }) => onChange(value, name)}
          name={name}
          value={value}
          key={name}
          {...props}
        >
          {options.map((option, index) => (
            <AntdRadio key={index} value={option.value}>
              {option.label}
            </AntdRadio>
          ))}
        </Radio>
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}

export default InputWrapper
