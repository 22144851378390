import { format, validate } from 'rut.js'

export function formatSeconds(seconds = 0) {
  return seconds > 3600
    ? new Date(seconds * 1000).toISOString().substr(11, 8)
    : new Date(seconds * 1000).toISOString().substr(14, 5)
}
export function capitalizeFirstLetter(word) {
  return word.replace(/\w/, (l) => l.toUpperCase())
}

export function formatRut(rut) {
  if (rut.length) {
    return format(rut)
  }
  return rut
}

export const isRutValid = (rut, excepctionalRuts) => {
  // exceptionalRuts vienen por mientras como parametro "mockeados"
  // Habrá un endpoint con un listado de ruts excepcionales más adelante
  if (excepctionalRuts.includes(rut)) {
    return true
  }

  return validate(rut) && rut.length > 7 && rut.length < 10
}

export function normalizeString(rawText) {
  return rawText.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)
}

const regexOptions = {
  phone: /^[0-9]{8,9}$/,
  email:
    /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/m,
}

export const regexValidator = (value, type) => {
  const validator = new RegExp(regexOptions[type])
  return validator.test(value)
}

export function numberWithDots(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `.`)
}

const SECONDS_PER_DAY = 86400
const HOURS_PER_DAY = 24
export const secondsToHms = (seconds) => {
  const days = Math.floor(seconds / SECONDS_PER_DAY)
  const remainderSeconds = seconds % SECONDS_PER_DAY
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19)
  return hms.replace(/^(\d+)/, (h) =>
    `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, `0`),
  )
}

export function validateEmail(email) {
  if (typeof email !== `undefined`) {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    )
    if (!pattern.test(email.trim())) {
      return false
    }

    return true
  }
}
