import Container from './Container'
import Typography from './Typography'

function ChartContainer({ title, isWaitingTime, children }) {
  return (
    <Container>
      <Container>
        <Typography>{title}</Typography>
      </Container>
      {!isWaitingTime && <Container>{children}</Container>}
    </Container>
  )
}
export default ChartContainer
