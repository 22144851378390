import palette from './palette'

// eslint-disable-next-line quotes
declare module '@mui/material/styles' {
  interface PropsColorOverrides {
    info: true
  }

  interface TypographyVariants {
    menuItem: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    menuItem?: React.CSSProperties
  }
}

// eslint-disable-next-line quotes
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuItem: true
  }
}

//                  0,  1,  2,  3,  4,  5,  6,  7,  8
const fontSizes = [12, 14, 16, 18, 20, 24, 32, 42, 70]

// 0: light, 1: regular, 2: medium, 3: bold
const fontWeights = [300, 400, 700, 900]

const typography = (): Record<string, unknown> => ({
  fontFamily: `Roboto`,
  h1: {
    fontWeight: fontWeights[2],
    fontSize: fontSizes[5],
  },
  menuItem: {
    fontWeight: 500,
    fontSize: fontSizes[2],
    color: palette.common.white,
  },
})

export default typography
