import { useState } from 'react'
import { ChromePicker } from 'react-color'
import Container from './Container'
import 'react-quill/dist/quill.snow.css'

const pickerAlignments = {
  bottom: `bottom`,
}

const pickerPositions = {
  [pickerAlignments.bottom]: {
    marginTop: `-210px`,
  },
}

function PreviewPicker({
  color = `#000000`,
  onChange = () => {},
  disableAlpha = false,
  pickerAlignment = null,
}) {
  const [isPickerOpen, openPicker] = useState(false)

  function togglePicker() {
    openPicker(!isPickerOpen)
  }
  return (
    <Container flexDirection="row">
      <Container
        width="2em"
        height="2em"
        padding="3"
        borderRadius="50%"
        backgroundColor={color}
        justifyContent="center"
        alignContent="center"
        onClick={togglePicker}
      />
      <Container
        position="absolute"
        zIndex="2"
        marginLeft="3em"
        {...(pickerPositions[pickerAlignment] ?? {})}
      >
        {isPickerOpen ? (
          <ChromePicker
            color={color}
            disableAlpha={disableAlpha}
            onChange={onChange}
          />
        ) : null}
      </Container>
    </Container>
  )
}
function DefaultPicker({ disableAlpha = false, onChange, ...props }) {
  return (
    <Container zIndex="2">
      <ChromePicker
        disableAlpha={disableAlpha}
        onChange={onChange}
        {...props}
      />
    </Container>
  )
}
const modes = {
  DEFAULT: `default`,
  PREVIEW: `preview`,
}
const ColorPickerByMode = {
  [modes.PREVIEW]: PreviewPicker,
  [modes.DEFAULT]: DefaultPicker,
}
function ColorPicker({
  disableAlpha = false,
  mode = modes.DEFAULT,
  onChange,
  ...props
}) {
  const Component = ColorPickerByMode[mode] ?? null
  if (Component) {
    return (
      <Component onChange={onChange} disableAlpha={disableAlpha} {...props} />
    )
  }
  return null
}

export default ColorPicker
