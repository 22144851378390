/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react'
import moment from 'moment'
import { Typography, Input, Container } from 'components'
import Slider from './Slider'

type Schedule = {
  start: string
  end: string
  startMinutes: number
  endMinutes: number
}
type Item = {
  key: number
  label: string
  isActive?: boolean
  schedule: Schedule[]
}
type Schema = Record<number, Item>

interface Props {
  schema?: Schema
  onChange?(schema: Schema): void
}
export const defaultSchema: Schema = {
  1: {
    key: 1,
    label: `Lunes`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  2: {
    key: 2,
    label: `Martes`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  3: {
    key: 3,
    label: `Miercoles`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  4: {
    key: 4,
    label: `Jueves`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  5: {
    key: 5,
    label: `Viernes`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  6: {
    key: 6,
    label: `Sabado`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
  7: {
    key: 7,
    label: `Domingo`,
    isActive: true,
    schedule: [
      { start: `08:00`, end: `21:00`, startMinutes: 480, endMinutes: 1260 },
    ],
  },
}
function TimePicker(props: Props): JSX.Element {
  const { schema = defaultSchema, onChange } = props
  function handleChange({ value, name, key }): void {
    const newSchema = { ...schema }
    newSchema[key][name] = value
    onChange?.(newSchema)
  }
  return (
    <Container width="100%">
      {Object.entries(schema).map(([key, item]) => (
        <Item
          onChange={(value, name) => handleChange({ value, name, key })}
          key={key}
          {...item}
        />
      ))}
    </Container>
  )
}

const Item: FC<Item & { onChange(value: unknown, name: string): void }> = ({
  onChange,
  label,
  schedule,
  isActive = true,
}) => {
  function handleSlideChange(
    [startMinutes, endMinutes]: number[],
    index: number,
  ): void {
    const startOfDay = moment().startOf(`day`)
    const value = {
      start: startOfDay.clone().add(startMinutes, `minutes`).format(`HH:mm`),
      end: startOfDay.clone().add(endMinutes, `minutes`).format(`HH:mm`),
      startMinutes,
      endMinutes,
    }
    const newSchedule = [...schedule]
    newSchedule[index] = value
    onChange(newSchedule, `schedule`)
  }
  return (
    <Container
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      height="2.5em"
      marginBottom="1em"
    >
      <Container width="20%" alignItems="baseline">
        <Input
          value={isActive}
          name="isActive"
          onChange={onChange}
          type="checkbox"
        />
        <Typography marginLeft="2">{label}</Typography>
      </Container>
      {schedule.map(({ start, end, startMinutes, endMinutes }, index) => (
        <>
          <Slider
            onInput={(value) => handleSlideChange(value, index)}
            max={1439}
            min={360}
            step={15}
            defaultValue={[startMinutes, endMinutes]}
            width="70%"
            height="100%"
            disabled={!isActive}
            thumbProps={{
              background: isActive ? `primary.main` : `transparent`,
            }}
            sliderProps={{
              background: isActive ? `primary.main` : `transparent`,
            }}
          />
          {start} - {end}
        </>
      ))}
    </Container>
  )
}

export default TimePicker
