import { PUBLIC_VAPID_KEY, Notification } from 'config/constants'

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = `=`.repeat((4 - (base64String.length % 4)) % 4)
  // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const getCurrentSubscription =
  async (): Promise<PushSubscription | null> => {
    try {
      if (`serviceWorker` in navigator) {
        const registration = await navigator.serviceWorker.ready
        if (!registration || !registration.pushManager) {
          return Promise.resolve(null)
        }
        const existedSubscription =
          await registration.pushManager.getSubscription()

        return Promise.resolve(existedSubscription)
      } else {
        return Promise.resolve(null)
      }
    } catch {
      //An error ocurred during Service Worker registration.
      return Promise.resolve(null)
    }
  }

export const createSubscription =
  async (): Promise<PushSubscription | null> => {
    try {
      if (
        `serviceWorker` in navigator &&
        Notification?.permission === `granted` &&
        PUBLIC_VAPID_KEY !== undefined
      ) {
        const registration = await navigator.serviceWorker.ready
        if (!registration || !registration.pushManager) {
          return Promise.resolve(null)
        }
        const existedSubscription =
          await registration.pushManager.getSubscription()
        if (existedSubscription) {
          return Promise.resolve(existedSubscription)
        }
        const subscription = await registration.pushManager.subscribe({
          applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
          userVisibleOnly: true,
        })
        return Promise.resolve(subscription)
      } else {
        return Promise.resolve(null)
      }
    } catch {
      //An error ocurred during Service Worker registration.
      return Promise.resolve(null)
    }
  }
