import { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  FlexboxProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system'
import AntdSwitch, { SwitchProps } from 'antd/es/switch'
import Container from '../Container'
import Typography from '../Typography'
interface BaseSwitchProps extends SwitchProps, ColorProps {}
const Switch: FC<BaseSwitchProps> = styled(AntdSwitch)<BaseSwitchProps>`
  &.ant-switch.ant-switch-checked {
    ${color}
  }
`
Switch.defaultProps = {
  backgroundColor: `primary.2`,
}

interface InputProps
  extends Omit<BaseSwitchProps, `onChange`>,
    FlexboxProps,
    SpaceProps,
    Omit<LayoutProps, `size`> {
  onChange(value: boolean, name?: string): void
  value: boolean
  name?: string
  error?: string
  placeholder?: string
  label?: string
  disabled?: boolean
}

const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  value,
  onChange,
  name,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Switch
          checked={value}
          onChange={(checked) => onChange(checked, name)}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
