import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'hooks'
import { format } from 'rut.js'
import Container from './Container'
import Input from './Input'
import Typography from './Typography'
import Button from './Button'
import Icon from './old/Icon'

function getSelectTagsOptions(values) {
  return values.map((value) => {
    return {
      name: value,
      id: value,
    }
  })
}
function getInitialState({ fields, defaultValues, selectedValues }) {
  const values = {}
  fields.forEach((field) => {
    values[field] = null
  })
  const formattedDefaultValues = {}
  Object.entries(defaultValues).forEach(([key, value]) => {
    formattedDefaultValues[key] = value
  })
  const formattedSelectedValues = {}
  Object.entries(selectedValues).forEach(([key, value]) => {
    formattedSelectedValues[key] = value
  })
  return {
    ...values,
    ...formattedDefaultValues,
    ...formattedSelectedValues,
  }
}

function Form({
  formSchema,
  onConfirm,
  defaultValues = {},
  extraProps,
  selectedValues = {},
  okText = `OK`,
  icon,
  isLoading,
  sideEffect,
  otherInputProps = {},
  ...props
}) {
  const { fields = [], ...fieldsById } = formSchema
  const initialState = getInitialState({
    fields,
    defaultValues,
    selectedValues,
  })
  const {
    values,
    errors,
    canSubmit,
    handleChange,
    handleSubmit,
    addressSuggestions,
    addressSelect,
  } = useForm({
    stateSchema: initialState,
    validationSchema: fieldsById,
    callback: onConfirm,
    sideEffect,
    extraProps,
  })

  const onDrop = useCallback((files) => {
    const [file] = files
    handleChange(file, `file`)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: `image/*, application/pdf`,
    maxFiles: 1,
  })

  const formatRut = (rut) => (rut ? format(rut) : ``)
  function formatValue(value, name) {
    if ([`rut`, `patientRut`].includes(name)) {
      return formatRut(value)
    }
    return value
  }

  const optionsByType = ({ type, source, tags = false }) => {
    if (type === `select`) {
      if (tags) {
        const splittedTags =
          extraProps[source] && typeof extraProps[source] === `string`
            ? extraProps[source].split(`,`)
            : extraProps[source]

        return splittedTags ? getSelectTagsOptions(splittedTags) : []
      }
      return extraProps[source]
    }
    if (type === `address`) {
      return addressSuggestions
    }
    return null
  }

  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      {...props}
    >
      {fields.map((fieldId) => {
        const {
          placeholder,
          isRequired,
          name,
          type,
          source,
          isActive,
          label,
          tags,
        } = fieldsById[fieldId]
        if (!isActive) {
          return null
        }
        return (
          <Input
            key={fieldId}
            type={type}
            placeholder={placeholder}
            color="primary.0"
            name={name}
            label={label}
            width="100%"
            paddingY="25px"
            marginBottom="0.5em"
            value={values[name] ? formatValue(values[name], name) : null}
            onChange={handleChange}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            required={isRequired}
            error={errors[name]}
            addressSelect={addressSelect}
            labelKey="name"
            valueKey="id"
            options={optionsByType({ type, source, tags })}
            {...otherInputProps}
          />
        )
      })}
      <Button
        width={{ _: `100%`, md: `20%` }}
        hoverProps={canSubmit ? { backgroundColor: `primary.2@0.9` } : null}
        margin="0 auto"
        marginY="3"
        padding="1em 2em"
        textDecoration="underline"
        backgroundColor={canSubmit ? `primary.2` : `grey.1`}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        <Typography
          textDecoration="underline"
          color={canSubmit ? `white` : `grey.3`}
          fontSize="4"
        >
          {okText}
          {icon && <Icon icon={icon} marginLeft="2" />}
        </Typography>
      </Button>
    </Container>
  )
}

export default Form
