import { useContext, useEffect, createContext } from 'react'
import { useStateWithMerge } from 'hooks'
import Container from 'components/Container'
import API from 'config/api'
import { useTheme } from './ThemeContext'
import { createSubscription, getCurrentSubscription } from 'utils'

const AuthContext = createContext(null)
export interface SignedUser {
  id: number
  name: string
  rut: string
  roleId: number
  corporationId: number
  divisionId: number
  unitId: number
  scheduleUnitId?: number
  scheduleUserId?: number
  lines: Array<number>
  hasSchedule: boolean
  hasDelivery: boolean
  hasDeliveryIntegrationFlow?: boolean
  pharmacyConfig?: Record<string, unknown>
  hasRoutingApiKey?: boolean
  hasPickup: boolean
  hasLines: boolean
  hasStock: boolean
  hasHoursConfirmation: boolean
  hasCoupons: boolean
}
interface State {
  user: SignedUser
  isLoading: boolean
}
const getInitialState = (): State => ({
  user: null,
  isLoading: true,
})

function AuthProvider({
  children,
}: {
  children: React.ReactChild
}): JSX.Element {
  const { mergeTheme } = useTheme()
  const [state, setState] = useStateWithMerge(getInitialState())

  useEffect(() => {
    async function getCurrentUser(): Promise<void> {
      const token = localStorage.getItem(
        process.env.REACT_APP_TOKEN_KEY || `saltala_local_token`,
      )
      if (token) {
        try {
          const subscription = await createSubscription()
          const user = (await API.getCurrentUser({
            subscription,
          })) as unknown as SignedUser
          if (user.corporationId) {
            try {
              const theme = await API.getTheme(user.corporationId)
              mergeTheme(theme)
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn(`Theme not found, default colors`)
            }
          }
          setState({ user })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e)
        }
      }
      setState({ isLoading: false })
    }
    getCurrentUser()
  }, [])
  function logIn({ token, user }): void {
    localStorage.setItem(
      process.env.REACT_APP_TOKEN_KEY || `saltala_local_token`,
      `Bearer ${token}`,
    )

    return setState({ user })
  }
  async function logOut(): Promise<void> {
    const subscription = await getCurrentSubscription()
    await API.logout({ subscription })
    localStorage.removeItem(
      process.env.REACT_APP_TOKEN_KEY || `saltala_local_token`,
    )
    localStorage.removeItem(
      process.env.REACT_APP_USER_KEY || `saltala_local_user`,
    )
    localStorage.removeItem(`theme`)

    return setState({ user: null })
  }
  const { user, isLoading } = state
  return (
    <AuthContext.Provider value={{ user, logIn, logOut }}>
      {isLoading ? (
        <Container width="100vw" height="100vh" loaderSize="60px" isLoading />
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

function useAuth(): {
  logIn: ({ token: string, user: SignedUser }) => void
  logOut: () => void
  user: SignedUser
} {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
