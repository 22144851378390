import { Controller } from 'react-hook-form'
import Container from './Container'
import Typography from './Typography'
export default function Validator({
  id = ``,
  defaultValue = ``,
  register,
  control,
  component,
  errors,
  ...props
}) {
  const CustomComponent = component.type
  const componentProps = component.props
  return (
    <Container {...props}>
      <Controller
        {...register(id)}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <CustomComponent {...componentProps} {...field} />
        )}
      />
      {errors?.[id] ? (
        <Container justifyContent="center" marginTop="5px">
          <Typography color="red">{errors?.[id].message}</Typography>
        </Container>
      ) : null}
    </Container>
  )
}
