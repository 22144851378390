import ReactDOM from 'react-dom'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'moment/locale/es'
import App from './App'
import { AuthProvider, ThemeProvider } from './context'
import 'antd/es/popover/style/index.css'
import 'antd/es/tag/style/index.css'
import 'antd/es/message/style/index.css'
import 'antd/es/tooltip/style/index.css'
import 'antd/es/upload/style/index.css'
import 'antd/es/button/style/index.css'
import * as serviceWorker from './serviceWorker'
import ThemeConfig from 'config/themeMui'
Sentry.init({
  dsn: `https://e6e874b3c96a42048d6f9ea616d88ab5@o381419.ingest.sentry.io/5277579`,
  environment: process.env.REACT_APP_SENTRY_ENV || `local`,
})

moment.locale(`es`)

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <ThemeConfig>
      <ThemeProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </ThemeConfig>
  </DndProvider>,
  document.getElementById(`root`),
)

serviceWorker.register()
