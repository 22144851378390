import { useContext, useEffect, createContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useStateWithMerge } from 'hooks'
import API from 'config/api'
import { useAuth } from './AuthContext'

const FilterContext = createContext()

function getFiltersOptions({
  divisions,
  divisionsById,
  units,
  unitsById,
  selectedCorporation,
  selectedDivision,
  showAllDivisions,
  showAllUnits,
  showAllCorporations,
  corporations,
  corporationsById,
  scheduleUnits = [],
  scheduleUnitsById,
  withScheduleUnits,
}) {
  let filteredDivisions = []
  if (withScheduleUnits) {
    // Se filtran las divisiones que tengan agenda
    scheduleUnits.forEach((unitId) => {
      const scheduleUnit = scheduleUnitsById[unitId]
      if (!filteredDivisions.includes(scheduleUnit.divisionId)) {
        filteredDivisions.push(scheduleUnit.divisionId)
      }
    })
  } else {
    filteredDivisions = divisions
  }
  if (selectedCorporation !== 0) {
    filteredDivisions = filteredDivisions.filter((divisionId) => {
      const division = divisionsById[divisionId]
      return (
        division.value === 0 || division.corporationId === selectedCorporation
      )
    })
  }

  function getUnitByType() {
    if (withScheduleUnits) {
      return { entities: scheduleUnits, entitiesById: scheduleUnitsById }
    }
    return { entities: units, entitiesById: unitsById }
  }
  const { entities, entitiesById } = getUnitByType()

  let filteredUnits = []
  if (selectedDivision === 0 && selectedCorporation === 0) {
    filteredUnits = entities
  } else if (selectedDivision === 0 && selectedCorporation !== 0) {
    filteredUnits = entities.filter((unitId) => {
      const unit = entitiesById[unitId]
      return (
        unit.value === 0 || unit.division.corporationId === selectedCorporation
      )
    })
  } else {
    let nextSelectedDivision = selectedDivision
    if (!filteredDivisions.includes(selectedDivision)) {
      nextSelectedDivision = filteredDivisions[0]
    }
    filteredUnits = entities.filter((unitId) => {
      const unit = entitiesById[unitId]
      return unit.value === 0 || unit.divisionId === nextSelectedDivision
    })
  }
  filteredUnits = filteredUnits.map((unitId) => entitiesById[unitId])
  const mappedCorporations = corporations.map(
    (corporationId) => corporationsById[corporationId],
  )
  filteredDivisions = filteredDivisions.map(
    (divisionId) => divisionsById[divisionId],
  )
  return {
    filteredDivisions: showAllDivisions
      ? [{ id: 0, name: `Todos` }, ...filteredDivisions]
      : filteredDivisions,
    filteredUnits: showAllUnits
      ? [{ id: 0, name: `Todos` }, ...filteredUnits]
      : filteredUnits,
    filteredCorporations: showAllCorporations
      ? [{ id: 0, name: `Todos` }, ...mappedCorporations]
      : mappedCorporations,
  }
}

function getInitialState({ corporationId, divisionId, unitId }) {
  return {
    corporations: [],
    selectedCorporation: corporationId || 0,
    divisions: [],
    filteredDivisions: [],
    selectedDivision: divisionId || 0,
    units: [],
    scheduleUnits: [],
    filteredUnits: [],
    selectedUnit: unitId || 0,
    lines: [],
    filteredLines: [],
    selectedLine: null,
    isLoading: true,
    error: null,
    showAllUnits: false,
    showAllDivisions: false,
    showAllCorporations: false,
    showLines: false,
    withScheduleUnits: false,
    previousPath: ``,
    withUnscopedGetLines: false,
  }
}

function FilterProvider({ children }) {
  const { user: signedUser } = useAuth()
  const [state, setState] = useStateWithMerge((_) =>
    getInitialState(signedUser),
  )
  const {
    selectedCorporation,
    selectedDivision,
    showLines,
    selectedUnit,
    withScheduleUnits,
    corporations,
    divisions,
    units,
    withUnscopedGetLines = false,
  } = state

  const { filteredDivisions, filteredUnits, filteredCorporations } =
    getFiltersOptions(state)
  useEffect(() => {
    if (filteredDivisions.length > 0 && filteredUnits.length > 0) {
      setState({
        selectedDivision: filteredDivisions[0].id,
        selectedUnit: filteredUnits[0].id,
      })
    }
  }, [selectedCorporation])
  useEffect(() => {
    if (filteredDivisions.length > 0 && filteredUnits.length > 0) {
      setState({
        selectedUnit: filteredUnits[0].id,
      })
    }
  }, [selectedDivision])
  useEffect(() => {
    function getSelectedUnit({ showAllUnits, withScheduleUnits, units }) {
      if (showAllUnits && !withScheduleUnits) {
        return 0
      }
      return withScheduleUnits ? signedUser.scheduleUnitId : units[0]
    }
    async function getUserFilters() {
      try {
        const {
          units,
          unitsById,
          divisions,
          divisionsById,
          corporations,
          corporationsById,
          scheduleUnits,
          scheduleUnitsById,
        } = await API.getUserFilters({
          withScheduleUnits,
        })

        setState((prevState) => {
          return {
            units,
            unitsById,
            divisions,
            divisionsById,
            corporations,
            corporationsById,
            scheduleUnits,
            scheduleUnitsById,
            selectedCorporation: prevState.showAllCorporations
              ? 0
              : corporations[0],
            selectedDivision: prevState.showAllDivisions ? 0 : divisions[0],
            selectedUnit: getSelectedUnit({
              units,
              scheduleUnits,
              showAllUnits: prevState.showAllUnits,
              withScheduleUnits,
            }),
            isLoading: false,
          }
        })
      } catch (error) {
        // handle error
      }
    }
    getUserFilters()
  }, [withScheduleUnits])

  useEffect(() => {
    async function getLines() {
      try {
        setState({ isLoading: true })
        const { lines: rawLines } = await API.getLines(
          selectedUnit,
          withUnscopedGetLines,
        )
        const linesById = {}
        const lines = []
        rawLines.forEach((line) => {
          if (signedUser.lines.length) {
            if (signedUser.lines.includes(line.id)) {
              linesById[line.id] = line
              lines.push(line.id)
            }
          } else {
            linesById[line.id] = line
            lines.push(line.id)
          }
        })
        setState({
          lines,
          linesById,
          selectedLine: lines,
          isLoading: false,
        })
      } catch (error) {
        // message.error(`No se ha podido listar las filas. Reintente`)
        setState({ isLoading: true })
      }
    }
    if (selectedUnit) {
      getLines()
    }
  }, [selectedUnit, showLines])

  function onChange(filters) {
    setState({ ...filters })
  }

  function showAllOptionFor(entity) {
    const stateByEntity = {
      showAllCorporations: `selectedCorporation`,
      showAllDivisions: `selectedDivision`,
      showAllUnits: `selectedUnit`,
    }
    if (entity in stateByEntity) {
      return setState({
        [entity]: true,
        [stateByEntity[entity]]: 0,
      })
    }
    return null
  }

  function resetFilters(showAllEntity) {
    setState({
      [showAllEntity]: false,
      selectedCorporation: corporations[0],
      selectedDivision: divisions[0],
      selectedUnit: units[0],
    })
  }

  return (
    <FilterContext.Provider
      value={{
        ...state,
        filteredDivisions,
        filteredUnits,
        filteredCorporations,
        resetFilters,
        showAllOptionFor,
        onChange,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

function useFilter(props) {
  const location = useLocation()
  const context = useContext(FilterContext)

  if (!context) {
    throw new Error(`useFilter must be used within a FilterProvider`)
  }

  useEffect(() => {
    function showAllOptions() {
      if (props.allCorporations) {
        context.showAllOptionFor(`showAllCorporations`)
      } else {
        context.resetFilters(`showAllCorporations`)
      }
      if (props.allDivisions) {
        context.showAllOptionFor(`showAllDivisions`)
      } else {
        context.resetFilters(`showAllDivisions`)
      }
      if (props.allUnits) {
        context.showAllOptionFor(`showAllUnits`)
      } else {
        context.resetFilters(`showAllUnits`)
      }
    }

    if (props) {
      // Formateo filtros unicamente si cambio de baseUrl
      if (context.previousPath !== location.pathname) {
        showAllOptions()
        context.onChange({ previousPath: location.pathname })
      }

      if (props.showLines) {
        // Mostrar filas
        context.onChange({ showLines: true })
      } else if (props.showLines === false) {
        context.onChange({ showLines: false })
      }

      // Con Unidades de Agenda
      if (props.withScheduleUnits) {
        context.onChange({ withScheduleUnits: true })
      } else if (props.withScheduleUnits === false) {
        context.onChange({ withScheduleUnits: false })
      }

      // Para administrador de modulos
      if (props.withUnscopedGetLines) {
        context.onChange({ withUnscopedGetLines: true })
      } else if (props.withUnscopedGetLines === false) {
        context.onChange({ withUnscopedGetLines: false })
      }
    }
  }, [location.pathname])
  return context
}

export { FilterProvider, useFilter }
