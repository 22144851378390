import { Suspense, lazy } from 'react'
import GlobalStyle from './GlobalStyle'
import { IconOld } from './components'
import { useAuth } from './context'
import { Notification } from './config/constants'
const Login = lazy(() => import(`./screens/Login`))
const MainApp = lazy(() => import(`./MainApp`))
//components
function App() {
  const { user } = useAuth()
  Notification?.requestPermission()
  return (
    <Suspense fallback={<IconOld icon="spinner" spin />}>
      {user ? <MainApp /> : <Login />}
      <GlobalStyle />
    </Suspense>
  )
}

export default App
