import { useEffect, useRef } from 'react'
import moment from 'moment'

function usePolling({
  pollingFn,
  pollingTimeInSeconds = 60,
  dependencies = [],
}) {
  const interval = useRef(null)
  const lastPoll = useRef(null)
  function cancelPolling() {
    clearInterval(interval.current)
  }

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }
    pollingFn()
    lastPoll.current = moment()
    interval.current = setInterval(() => {
      pollingFn(lastPoll.current)
      lastPoll.current = moment()
    }, pollingTimeInSeconds * 1000)

    return () => clearInterval(interval.current)
  }, dependencies)
  return cancelPolling
}

export default usePolling
