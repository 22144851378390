import { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  FlexboxProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system'
import AntdCheckbox, { CheckboxProps } from 'antd/es/checkbox'
import Container from '../Container'
import Typography from '../Typography'

interface BaseCheckboxProps extends CheckboxProps, ColorProps {}

const Checkbox: FC<BaseCheckboxProps> = styled(AntdCheckbox)<BaseCheckboxProps>`
  width: 100% !important;
  & .ant-checkbox-checked .ant-checkbox-inner {
    ${color}
  }
`

Checkbox.defaultProps = {
  backgroundColor: `primary.2`,
}

interface InputProps
  extends Omit<BaseCheckboxProps, `onChange`>,
    FlexboxProps,
    SpaceProps,
    LayoutProps {
  onChange(value: boolean, name?: string): void
  error?: string
  label?: string
}

const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  value,
  onChange,
  name,
  flexDirection = `column`,
  justifySelf = null,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Checkbox
          checked={value}
          name={name}
          onChange={({ target: { checked } }) => onChange(checked, name)}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
