import moment from 'moment'
import { format } from 'rut.js'
import Container from '../Container'
import Typography from '../Typography'
import Icon from '../old/Icon'

const colorsByStatus = {
  1: `primary.2@0.25`, //Disponible
  2: `gray.3@0.2`, //bloqueado
  3: `gray.3@0.2`, //Agendada
  4: `success@0.25`, //Atendida
  5: `error@0.25`, //Ausente
  6: `primary.2@0.25`, //Arribada
  7: `primary.1@0.25`, //Iniciada
  8: `gray.3@0.2`, //Confirmada
}

function EventItem({ event }) {
  const {
    patientFirstName,
    patientLastName,
    patientRut,
    overbooks,
    appointmentStatusId,
    reservationStart,
    onClick,
    fontSize,
  } = event
  return (
    <Container
      padding="1"
      paddinLeft="3"
      borderRadius="5px"
      backgroundColor={colorsByStatus[appointmentStatusId]}
      border={overbooks.length > 0 ? `1px solid` : null}
      borderColor="error"
      alignItems="center"
      flexWrap="no-wrap"
      justifyContent="space-between"
      width="100%"
      position="relative"
      onClick={() => onClick(event)}
    >
      <Typography
        fontSize={fontSize}
        textTransform="capitalize"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        fontWeight="500"
        color="gray.3"
        paddingLeft="2"
      >
        {appointmentStatusId === 1 || appointmentStatusId === 2
          ? null
          : `${format(patientRut)} | ${patientFirstName || ``} ${
              patientLastName || ``
            }`.toLowerCase()}
      </Typography>
      {appointmentStatusId === 1 && (
        <Typography
          position="absolute"
          bottom="2"
          right="2"
          fontStyle="italic"
          fontSize="0"
          color="primary.2"
        >
          {moment(reservationStart).format(`HH:mm`)}
        </Typography>
      )}
      {appointmentStatusId === 8 && <Icon color="gray.3" icon="checkCircle" />}
      {appointmentStatusId === 2 && <Icon color="gray.3" icon="minusCircle" />}
    </Container>
  )
}

export default EventItem
