import moment from 'moment'
import {
  Input,
  InputPassword,
  Select,
  Radio,
  Checkbox,
  TimePicker,
  DatePicker,
  RangePicker,
  InputNumber,
  Switch,
  TextArea,
} from './styles'
import Container from './Container'
import Typography from './Typography'
import Button from './Button'

const inputType = {
  text({ value, onChange, placeholder, name, disabled, inputType, ...props }) {
    return (
      <Input
        placeholderFontStyle="italic"
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
        type={inputType}
      />
    )
  },
  textArea({
    value,
    onChange,
    placeholder,
    name,
    disabled,
    inputType,
    ...props
  }) {
    return (
      <TextArea
        placeholderFontStyle="italic"
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
        type={inputType}
      />
    )
  },
  password({ value, onChange, placeholder, name, disabled, ...props }) {
    return (
      <InputPassword
        placeholderFontStyle="italic"
        onChange={({ target: { name, value } }) => onChange(value, name)}
        placeholder={placeholder || `Escribe aquí`}
        name={name}
        value={value}
        disabled={disabled}
        {...props}
      />
    )
  },
  select({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    labelKey = `label`,
    valueKey = `value`,
    nullKey = false,
    ...props
  }) {
    options = options || []
    return (
      <Select
        onChange={(value) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value === null && !nullKey ? undefined : value}
        key={name}
        {...props}
      >
        {options.map((option) => (
          <Select.Option key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </Select.Option>
        ))}
      </Select>
    )
  },
  time({
    value,
    onChange,
    placeholder,
    name,
    period,
    allowClear = false,
    format = `HH:mm`,
    ...props
  }) {
    return (
      <TimePicker
        value={value ? moment(value, format) : null}
        key={name}
        onChange={(_date, dateString) => {
          const newValue = moment(dateString, format)
          if (newValue.isValid()) {
            onChange(newValue.format(format), name)
          }
        }}
        format={format}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        allowClear={allowClear}
        {...props}
      />
    )
  },
  date({ value, onChange, placeholder, name, format, ...props }) {
    return (
      <DatePicker
        value={value ? moment(value, format) : null}
        key={name}
        onChange={(date) => onChange(date, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        format={format}
        {...props}
      />
    )
  },
  dateRange({ value, onChange, name, ...props }) {
    return (
      <RangePicker
        value={value}
        onChange={(dateRange) => onChange(dateRange, name)}
        name={name}
        {...props}
      />
    )
  },
  radio({ value, onChange, placeholder, options = [], name, ...props }) {
    return (
      <Radio.Group
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.map((option) => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    )
  },
  radioButton({ value, onChange, placeholder, options = [], name, ...props }) {
    return (
      <Radio.Group
        onChange={({ target: { value } }) => onChange(value, name)}
        placeholder={placeholder || `Seleccionar`}
        name={name}
        value={value}
        key={name}
        {...props}
      >
        {options.map((option) => (
          <Radio.Button
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    )
  },
  checkbox({ value, onChange, name, ...props }) {
    return (
      <Checkbox
        checked={value}
        name={name}
        onChange={({ target: { checked } }) => onChange(checked, name)}
        {...props}
      />
    )
  },
  switch({ value, onChange, name, ...props }) {
    return (
      <Switch
        checked={value}
        name={name}
        onChange={(checked) => onChange(checked, name)}
        {...props}
      />
    )
  },
  number({ value, name, onChange, addonBefore = null, ...props }) {
    return (
      <Container alignItems="stretch" width="100%">
        {addonBefore ? (
          <Container
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray.0"
            borderRadius="4px 0px 0px 4px"
            borderY="1px solid"
            borderLeft="1px solid"
            borderColor="gray.1"
            width="20%"
          >
            <Typography fontWeight="3" color="gray.3">
              {addonBefore}
            </Typography>
          </Container>
        ) : null}
        <InputNumber
          value={value}
          onChange={(value) => onChange(value, name)}
          name={name}
          width={addonBefore ? `80% !important` : null}
          borderRadius={addonBefore ? `0px 4px 4px 0px` : null}
          {...props}
        />
      </Container>
    )
  },
  address({
    value,
    onChange,
    placeholder,
    options = [],
    name,
    addressSelect,
    addressRef,
    ...props
  }) {
    const { status, data } = options
    return (
      <>
        <Input
          name={name}
          onChange={({ target: { name, value } }) => onChange(value, name)}
          placeholder={placeholder || undefined}
          value={value}
          key={name}
          {...props}
        />
        {status === `OK` ? (
          <Container position="relative" width="100%">
            <Container
              position="absolute"
              top={-12}
              backgroundColor="white"
              zIndex="999"
              border="1px solid"
              borderColor="gray.1"
              width="100%"
            >
              {data.map((suggestion) => {
                const {
                  id,
                  structured_formatting: {
                    main_text: mainText,
                    secondary_text: secondaryText,
                  },
                } = suggestion
                return (
                  <Container
                    width="100%"
                    paddingX="2"
                    paddingY="1"
                    key={id}
                    onClick={() => addressSelect(suggestion)}
                    alignItems="center"
                    hoverProps={{ backgroundColor: `gray.0` }}
                  >
                    <Typography fontSize="2" fontWeight="2" marginRight="1">
                      {mainText}
                    </Typography>
                    <Typography color="grey.1" fontWeight="0" fontSize="1">
                      {secondaryText}
                    </Typography>
                  </Container>
                )
              })}
            </Container>
          </Container>
        ) : null}
      </>
    )
  },
  file({ isDragActive, getRootProps, getInputProps, value, onChange }) {
    return (
      <Container flexDirection="column" width="100%">
        {value ? (
          <Container
            borderRadius="5px"
            width="100%"
            border="1px dashed"
            borderColor="grey.2"
            padding="3"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography color="primary.2" fontSize="large" fontWeight="bold">
              {value?.name}
            </Typography>
            <Button
              onClick={() => onChange(null, `file`)}
              backgroundColor="transparent"
              color="primary.2"
              textDecoration="underline"
              marginRight="2"
              cursor="pointer"
            >
              descartar
            </Button>
          </Container>
        ) : (
          <Container
            {...getRootProps()}
            border="1px dashed"
            borderColor="grey.1"
            borderRadius="5px"
            padding="3"
            height="85%"
            justifyContent="center"
            alignItems="center"
            width="100% !important"
            backgroundColor="grey.4"
          >
            <input {...getInputProps()} />
            <Typography
              color="primary.2"
              fontSize="large"
              fontWeight="semi-bold"
            >
              {isDragActive
                ? `Suelta el archivo aquí`
                : `Haz click o arrastra el archivo`}
            </Typography>
          </Container>
        )}
      </Container>
    )
  },
}

const InputWrapper = ({
  type = `text`,
  label = null,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  onChange = (_, __) => {},
  flexDirection = `column`,
  justifySelf = null,
  ...props
}) => {
  const Component = inputType[type]
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Component
          error={error}
          onChange={onChange}
          color={color}
          type={type}
          {...props}
        />
      </Container>
      {error != null ? (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      ) : null}
    </>
  )
}
export default InputWrapper
