import { FC } from 'react'
import moment, { MomentInput } from 'moment'
import {
  color,
  FlexboxProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system'
import styled from 'styled-components'
import AntdDatePicker from 'antd/es/date-picker'
import { DatePickerProps } from 'antd/es/date-picker/interface'
import Container from '../Container'
import Typography from '../Typography'

interface BaseDatePickerProps extends DatePickerProps, ColorProps {}

const DatePicker: FC<BaseDatePickerProps> = styled(
  AntdDatePicker,
)<BaseDatePickerProps>`
  &.ant-calendar-picker {
    width: 100% !important;
    & .ant-calendar-picker-input.ant-input {
      ${color}
      & ::placeholder {
        ${color}
      }
    }
  }
`

DatePicker.defaultProps = {
  color: `gray.1`,
}

interface InputProps
  extends BaseDatePickerProps,
    FlexboxProps,
    SpaceProps,
    Omit<LayoutProps, `size`> {
  error?: string
  label?: string
  disabled?: boolean
  onChange(value: MomentInput, name?: string, dateString?: string): void
}
const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  value,
  onChange,
  placeholder,
  name,
  format,
  flexDirection = `column`,
  justifySelf = null,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <DatePicker
          value={value ? moment(value, format) : null}
          key={name}
          onChange={(date, dateString) => onChange(date, name, dateString)}
          placeholder={placeholder || `Seleccionar`}
          name={name}
          format={format}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
