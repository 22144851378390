import {
  BarChart,
  LineChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts'

const chartComponents = {
  line: LineChart,
  bar: BarChart,
  barStack: BarChart,
}

const categoryComponents = {
  line({ id, name, color }) {
    return (
      <Line
        key={`line-${id}`}
        id={`${id}`}
        name={name}
        dataKey={id}
        stroke={color}
        type="monotone"
      />
    )
  },
  bar({ id, name, color }) {
    return (
      <Bar
        key={`bar-${id}`}
        id={`${id}`}
        name={name}
        dataKey={id}
        fill={color}
      />
    )
  },
  barStack({ id, name, color, stackId }) {
    return (
      <Bar
        key={`barStack-${id}`}
        id={`${id}`}
        name={name}
        dataKey={id}
        fill={color}
        stackId={stackId}
      />
    )
  },
}

const XYChart = ({
  data,
  categories = [],
  chartType,
  xLabel,
  yLabel,
  hiddenLabels,
  renderLegend,
}) => {
  const Component = chartComponents[chartType]
  const mappedCategories = categories.map(categoryComponents[chartType])
  return (
    <Component
      width={800}
      height={hiddenLabels ? 250 : 300}
      data={data}
      margin={{
        top: 20,
        right: 0,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" hide={hiddenLabels}>
        <Label value={xLabel} offset={0} position="insideBottomRight" />
      </XAxis>
      <YAxis domain={[0, `dataMax`]}>
        <Label angle={-90} value={yLabel} offset={0} position="left" />
      </YAxis>
      <Tooltip />
      <Legend
        content={renderLegend}
        layout="vertical"
        align="right"
        verticalAlign="middle"
      />
      {mappedCategories}
    </Component>
  )
}

export default XYChart
