import styled from 'styled-components'
import {
  compose,
  variant,
  space,
  layout,
  color,
  typography,
  border,
  textStyle,
  position,
  system,
  flexbox,
} from 'styled-system'

const Anchor = styled.a`
  margin: 0;
  ${compose(
    space,
    variant,
    layout,
    color,
    typography,
    border,
    position,
    textStyle,
    flexbox,
    system({
      textDecoration: true,
      textTransform: true,
      whiteSpace: true,
      textOverflow: true,
    }),
  )}
`

Anchor.defaultProps = {
  maxWidth: `100%`,
}

function AnchorWrapper({ children, href, ...props }) {
  return (
    <Anchor href={href} {...props}>
      {children}
    </Anchor>
  )
}

export default AnchorWrapper
