import { FC } from 'react'
import RangeSlider from 'react-range-slider-input'
import styled from 'styled-components'
import {
  compose,
  background,
  BackgroundProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  position,
  PositionProps,
  grid,
  GridProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
} from 'styled-system'
import { colorsWithOpacity, backgroundGradient } from 'utils'

interface SliderStyleProps
  extends BackgroundProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    PositionProps,
    GridProps,
    TypographyProps,
    ColorProps {}
interface Props extends SliderStyleProps {
  id?: string
  className?: string
  min?: number
  max?: number
  step?: number
  defaultValue?: number[]
  value?: number[]
  onInput?(value: number[]): void
  onThumbDragStart?()
  onThumbDragEnd?()
  onRangeDragStart?()
  onRangeDragEnd?()
  disabled?: boolean
  rangeSlideDisabled?: boolean
  thumbsDisabled?: boolean[]
  orientation?: string
  thumbProps?: SliderStyleProps
  sliderProps?: SliderStyleProps
}

const Slider: FC<Props> = styled<FC<Props>>(RangeSlider)`
  &.range-slider {
    ${compose(
      background,
      space,
      layout,
      flexbox,
      border,
      position,
      grid,
      typography,
      color,
      colorsWithOpacity,
      backgroundGradient,
    )}
    overflow: hidden;
  }
  &.range-slider .range-slider__thumb {
    width: 18px;
    height: 38px;
    border-radius: 4px;
    // background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E")
    //   #fff;
    background-repeat: no-repeat;
    background-position: center;
    ${({ thumbProps }) =>
      compose(
        background,
        space,
        layout,
        flexbox,
        border,
        position,
        grid,
        typography,
        color,
        colorsWithOpacity,
        backgroundGradient,
      )({ ...thumbProps })}
  }
  &.range-slider .range-slider__range {
    ${({ sliderProps }) =>
      compose(
        background,
        space,
        layout,
        flexbox,
        border,
        position,
        grid,
        typography,
        color,
        colorsWithOpacity,
        backgroundGradient,
      )({ ...sliderProps })}
  }
`

Slider.defaultProps = {
  background: `#f5f5f5`,
}
export default Slider
