import styled from 'styled-components'
import {
  compose,
  system,
  layout,
  space,
  border,
  flexbox,
  typography,
} from 'styled-system'
import { Link } from 'react-router-dom'

import { colorsWithOpacity, backgroundGradient } from 'utils'
const LinkStyled = styled(Link)`
  ${compose(
    layout,
    space,
    flexbox,
    colorsWithOpacity,
    backgroundGradient,
    border,
  )}
  & > * {
    ${compose(colorsWithOpacity, typography, system({ textDecoration: true }))}
  }

  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        colorsWithOpacity,
        backgroundGradient,
        border,
      )({ ...props, ...hoverProps })}
    & > * {
      ${({ hoverProps, ...props }) =>
        compose(
          colorsWithOpacity,
          typography,
          system({ textDecoration: true }),
        )({ ...props, ...hoverProps })}
    }
  }
`
LinkStyled.defaultProps = {
  display: `flex`,
  justifyContent: ` center`,
  alignItems: ` center`,
  width: `100%`,
  padding: `0.8em`,
  hoverProps: null,
  maxWidth: `100%`,
}

export default LinkStyled
