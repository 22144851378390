import { useEffect, useRef, FC } from 'react'
import { CSVLink } from 'react-csv'
import Typography from './Typography'
import Icon from './old/Icon'
import {
  LayoutProps,
  ColorProps,
  SpaceProps,
  BorderProps,
  FontSizeProps,
} from 'styled-system'
type Props = LayoutProps &
  ColorProps &
  SpaceProps &
  BorderProps &
  FontSizeProps & {
    isAsync?: boolean
    data: Array<Record<string, unknown>>
    isLoading?: boolean
    children?: unknown
    headers: Array<{ label: string; key: string }>
    filename: string
  }
const CSVButton: FC<Props> = ({
  isAsync = false,
  data = [],
  children,
  isLoading,
  headers = [],
  filename = ``,
}) => {
  const linkRef = useRef(null)

  useEffect(() => {
    const existData = data?.length > 0
    if (isAsync && existData && linkRef.current) {
      setTimeout(() => linkRef.current.link.click(), 0)
    }
  }, [data])

  if (isAsync) {
    return (
      <>
        {isLoading ? (
          <Typography color="primary.2" marginLeft="3">
            Generando CSV
            <Icon marginLeft="1" icon="spinner" spin />
          </Typography>
        ) : (
          children
        )}
        {data ? (
          <CSVLink
            ref={linkRef}
            data={data}
            headers={headers}
            filename={filename}
          />
        ) : null}
      </>
    )
  }
  return (
    <>
      <CSVLink data={data} headers={headers} filename={filename}>
        {children}
      </CSVLink>
    </>
  )
}

export default CSVButton
