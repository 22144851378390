import { useCallback, FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { Container, Typography } from 'components'

interface Props {
  fileHandler: (file) => void
  acceptedTypes: string
  maxFiles: number
}

const FileInput: FC<Props> = ({
  fileHandler,
  acceptedTypes,
  maxFiles,
}: Props) => {
  const onDrop = useCallback((files) => {
    const [file] = files
    fileHandler(file)
  }, [])

  const uploadInputProps = {
    width: `100%`,
    border: `1px dashed gray`,
    borderRadius: `4px`,
    height: `5rem`,
    justifyContent: `center`,
    alignItems: `center`,
    marginY: `2`,
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    maxFiles,
  })
  return (
    <Container {...getRootProps()} {...uploadInputProps}>
      <input {...getInputProps()} />
      <Typography color="gray.2" fontStyle="italic">
        {isDragActive
          ? `Suelta el archivo aquí`
          : `Haz click o arrastra el archivo`}
      </Typography>
    </Container>
  )
}

export default FileInput
