import styled from 'styled-components'
import {
  compose,
  system,
  color,
  ColorProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { TabPaneProps, TabsProps } from 'antd/es/tabs'
import { Tabs as AntTabs } from 'antd'
import 'antd/es/tabs/style/index.css'
import { FC } from 'react'

const { TabPane: AntTabPane } = AntTabs

interface BaseTabProps
  extends Omit<TabsProps, `color`>,
    ColorProps,
    Omit<LayoutProps, `size`>,
    TypographyProps {
  activeTabColor?: ColorProps[`color`]
  selectedTabColor?: ColorProps[`backgroundColor`]
}
const tabColor = system({
  activeTabColor: {
    property: `color`,
    scale: `colors`,
  },
})

const activeTabColor = system({
  selectedTabColor: {
    property: `backgroundColor`,
    scale: `colors`,
  },
})
const Tabs: FC<BaseTabProps> = styled(AntTabs)<BaseTabProps>`
  width: 100%;
  ${compose(layout)}
  & .ant-tabs-nav-wrap {
    ${compose(color, typography)}
  }
  & .ant-tabs-ink-bar {
    ${activeTabColor}
  }
  & .ant-tabs-tab.ant-tabs-tab-active {
    ${compose(tabColor, typography)}
    font-weight: 900 !important;
  }
  & .ant-tabs-nav .ant-tabs-tab {
    font-weight: 400;
    &:hover {
      ${tabColor}
    }
  }
`

Tabs.defaultProps = {
  width: `100%`,
  activeTabColor: `primary.0`,
  selectedTabColor: `gray.3`,
  fontSize: `4`,
}

interface BaseTabPaneProps extends TabPaneProps, ColorProps {}

const TabPane: FC<BaseTabPaneProps> = styled(AntTabPane)<BaseTabPaneProps>`
  ${color}
`

export { Tabs, TabPane }
