import { FC } from 'react'
import styled from 'styled-components'
import {
  system,
  compose,
  background,
  space,
  layout,
  color,
  border,
  typography,
  BackgroundProps,
  TypographyProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
} from 'styled-system'
import AntdInput, { InputProps } from 'antd/es/input'
import Container from '../Container'
import Typography from '../Typography'
interface BaseInputProps
  extends Omit<InputProps, `color` | `size` | `width` | `height`>,
    BackgroundProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    TypographyProps {
  placeholderFontSize?: TypographyProps[`fontSize`]
  placeholderFontStyle?: TypographyProps[`fontStyle`]
  placeholderColor?: ColorProps[`color`]
}
const placeholderStyles = system({
  placeholderFontSize: {
    property: `fontSize`,
    scale: `fontSizes`,
  },
  placeholderFontStyle: {
    property: `fontStyle`,
  },
  placeholderColor: {
    property: `color`,
    scale: `colors`,
  },
})

const Input: FC<BaseInputProps> = styled(AntdInput)<BaseInputProps>`
  &.ant-input {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${placeholderStyles}
    }
  }
`

interface InputWrapperProps
  extends Omit<BaseInputProps, `onChange`>,
    FlexboxProps {
  label?: string
  error?: string
  onChange: (value: string, name: string) => void
}

const InputWrapper: FC<InputWrapperProps> = ({
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  onChange,
  error = null,
  label,
  value,
  placeholder,
  name,
  disabled,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Input
          placeholderFontStyle="italic"
          onChange={({ target: { name, value } }) => onChange(value, name)}
          placeholder={placeholder || `Escribe aquí`}
          name={name}
          value={value}
          disabled={disabled}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}

export default InputWrapper
