import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { TOKEN_KEY } from 'config/constants'

const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem(
    process.env.REACT_APP_TOKEN_KEY || TOKEN_KEY,
  )
  if (token) {
    config.headers.Authorization = token
  }
  return config
}
function responseHandler<T>(response: AxiosResponse): T {
  const {
    data: { data, error, success },
  } = response
  if (success) {
    return data
  } else {
    throw error
  }
}
export function getAxiosInstance(baseURL: string): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL,
    validateStatus() {
      return true
    },
  })
  axiosInstance.interceptors.request.use(requestHandler, (error) =>
    Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(responseHandler, (error) =>
    Promise.reject(error),
  )
  return axiosInstance
}
