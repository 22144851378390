//KEYS FOR LOCAL STORAGE
export const TOKEN_KEY = `saltala_local_token`

function getServerHost(url: string, port: number): string {
  const apiUrl = url
  const env = process.env.NODE_ENV
  if (apiUrl != null) {
    return apiUrl
  } else {
    return env === `production`
      ? `http://${window.location.host}/api/v1`
      : `http://${window.location.hostname}:${port}/api/v1`
  }
}

// URLs
export const SERVER_HOST = getServerHost(process.env.REACT_APP_API_URL, 4000)
export const OMNICHANNEL_SERVER_HOST = getServerHost(
  process.env.REACT_APP_OMNICHANNEL_API_URL,
  4001,
)
export const API_BASE_URL = `${SERVER_HOST}`
export const OMNICHANNEL_API_BASE_URL = `${OMNICHANNEL_SERVER_HOST}`

export const validationModes = {
  onSubmit: `onSubmit`,
  onChange: `onChange`,
  onBlur: `onBlur`,
  onTouched: `onTouched`,
}

export const roles = {
  SUPERADMIN: 1,
  ADMINISTRADOR_CORP: 2,
  ADMINISTRADOR_DIV: 3,
  ADMINISTRADOR_UNI: 4,
  ADMISIONISTA: 5,
  ADMINISTRADOR_AGENDA: 6,
  //...ETC
}

export const getRoleName = (roleId: number): string => {
  switch (roleId) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15: {
      return `Administrador`
    }
    case 7: {
      return `Profesional`
    }
    case 8: {
      return `Recepcionista`
    }
  }
}
//WEBPUSH
export const PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY

export const Notification = window.Notification || null
