/* eslint-disable no-console */
const isLocalhost = Boolean(
  window.location.hostname === `localhost` ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === `[::1]` ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

export function register(): void {
  if (`serviceWorker` in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      return
    }

    window.addEventListener(`load`, () => {
      const swUrl = `service-worker.js`

      if (isLocalhost) {
        // This is running on localhost.
        // Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            `This web app is being served cache-first by a service worker`,
          )
        })
      } else {
        registerValidSW(swUrl)
      }
    })
  }
}

function registerValidSW(swUrl): void {
  navigator.serviceWorker.register(swUrl).catch((error) => {
    console.error(`Error during service worker registration:`, error)
  })
}

function checkValidServiceWorker(swUrl): void {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get(`content-type`)
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf(`javascript`) === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl)
      }
    })
    .catch(() => {
      console.log(
        `No internet connection found. App is running in offline mode.`,
      )
    })
}

export function unregister(): void {
  if (`serviceWorker` in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
