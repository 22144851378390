import { FC } from 'react'
import styled from 'styled-components'
import {
  compose,
  background,
  space,
  layout,
  color,
  border,
  typography,
  BackgroundProps,
  TypographyProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
} from 'styled-system'
import AntdInputNumber, { InputNumberProps } from 'antd/es/input-number'
import Container from '../Container'
import Typography from '../Typography'
interface BaseInputNumberProps
  extends Omit<InputNumberProps, `width` | `height` | `color`>,
    BackgroundProps,
    SpaceProps,
    ColorProps,
    BorderProps,
    TypographyProps,
    Omit<LayoutProps, `size`> {}

const InputNumber: FC<BaseInputNumberProps> = styled(
  AntdInputNumber,
)<BaseInputNumberProps>`
  &.ant-input-number {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
  & .ant-input-number-input {
    ${typography}
  }
`

InputNumber.defaultProps = {
  fontSize: `1`,
}

interface InputProps
  extends Omit<BaseInputNumberProps, `onChange`>,
    FlexboxProps {
  error?: string
  label?: string
  addonBefore?: string
  onChange: (value: number, name: string) => void
}

const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  value,
  name,
  onChange,
  addonBefore = null,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Container alignItems="stretch" width="100%">
          {addonBefore ? (
            <Container
              alignItems="center"
              justifyContent="center"
              backgroundColor="gray.0"
              borderRadius="4px 0px 0px 4px"
              borderY="1px solid"
              borderLeft="1px solid"
              borderColor="gray.1"
              width="20%"
            >
              <Typography fontWeight="3" color="gray.3">
                {addonBefore}
              </Typography>
            </Container>
          ) : null}
          <InputNumber
            value={value}
            onChange={(value) => onChange(value, name)}
            name={name}
            width={addonBefore ? `80% !important` : null}
            borderRadius={addonBefore ? `0px 4px 4px 0px` : null}
            {...props}
          />
        </Container>
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
