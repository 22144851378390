import { FC } from 'react'
import styled from 'styled-components'
import {
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
} from 'styled-system'
import AntdDatePicker from 'antd/es/date-picker'
import {
  RangePickerProps,
  RangePickerValue,
} from 'antd/es/date-picker/interface'
import Container from '../Container'
import Typography from '../Typography'

const RangePicker: FC<RangePickerProps> = styled(
  AntdDatePicker.RangePicker,
)<RangePickerProps>``

interface InputProps
  extends Omit<RangePickerProps, `onChange`>,
    FlexboxProps,
    SpaceProps,
    Omit<LayoutProps, `size`> {
  onChange(
    value: RangePickerValue,
    name?: string,
    dateString?: [string, string],
  ): void
  error?: string
  label?: string
  color?: ColorProps[`color`]
}
const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  value,
  onChange,
  name,
  flexDirection = `column`,
  justifySelf = null,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <RangePicker
          value={value}
          onChange={(dateRange, dateString) =>
            onChange(dateRange, name, dateString)
          }
          name={name}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
