export function handlePublicConfig({
  value,
  name,
  path = `/`, // por defecto se setean props en el root de publicCOnfig
  publicConfig,
  onChange,
  pathConfig = `publicConfig`,
}) {
  const nextPublicConfig = { ...publicConfig } || {}
  if (path !== `/` && !nextPublicConfig[path]) {
    nextPublicConfig[path] = {}
  }
  if (path === `/`) {
    nextPublicConfig[name] = value
  } else {
    nextPublicConfig[path][name] = value
  }
  onChange(nextPublicConfig, pathConfig)
}
