import { notification as antdNotification } from 'antd'

export default function useNotification() {
  function format(error) {
    const message = typeof error === `string` ? error : error?.message
    return message
  }

  const notification = {
    success: ({ message, ...props }) =>
      antdNotification.success({ message: format(message), ...props }),
    error: ({ message, ...props }) =>
      antdNotification.error({ message: format(message), ...props }),
    warn: ({ message, ...props }) =>
      antdNotification.warn({ message: format(message), ...props }),
    info: ({ message, ...props }) =>
      antdNotification.info({ message: format(message), ...props }),
  }

  return { notification }
}
