import { FC } from 'react'
import styled from 'styled-components'
import {
  compose,
  system,
  layout,
  LayoutProps,
  ColorProps,
  SpaceProps,
  BorderProps,
  TypographyProps,
} from 'styled-system'
import { TableProps } from 'antd/es/table'
import { Table as AntTable } from 'antd'
import { getColorWithOpacity } from '../utils/styled'
import 'antd/es/table/style/index.css'
import 'antd/es/pagination/style/index.css'

interface TableExtendedProps<T>
  extends TableProps<T>,
    Omit<LayoutProps, `size`>,
    ColorProps,
    SpaceProps,
    BorderProps,
    TypographyProps {
  headerBackgroundColor?: ColorProps[`backgroundColor`]
  headerWeight?: TypographyProps[`fontWeight`]
  headerFontSize?: TypographyProps[`fontSize`]
  headerColor?: ColorProps[`color`]
  headerPadding?: SpaceProps[`padding`]
  lastRowWeight?: TypographyProps[`fontWeight`]
  dividerMiddle?: BorderProps[`borderRight`]
  oddBackgroundColor?: ColorProps[`backgroundColor`]
}

function TableWrapper<T>(props: TableExtendedProps<T>): JSX.Element {
  const Table: FC<TableExtendedProps<T>> = styled(AntTable)<
    TableExtendedProps<T>
  >`
    width: 100% !important;
    ${layout}
    .ant-table-thead > tr > th {
      ${compose(
        system({
          headerBackgroundColor: {
            property: `backgroundColor`,
            scale: `colors`,
            transform: getColorWithOpacity,
          },
          headerWeight: {
            property: `fontWeight`,
            scale: `fontWeights`,
          },
          headerFontSize: {
            property: `fontSize`,
            scale: `fontSizes`,
          },
          headerColor: {
            property: `color`,
            scale: `colors`,
          },
          headerPadding: {
            property: `padding`,
          },
        }),
      )}
    }
    & .ant-table-tbody tr:last-child > td {
      ${compose(
        system({
          lastRowWeight: {
            property: `fontWeight`,
            scale: `fontWeights`,
          },
        }),
      )}
      & > h1 {
        ${compose(
          system({
            lastRowWeight: {
              property: `fontWeight`,
              scale: `fontWeights`,
            },
          }),
        )}
      }
    }
    & .ant-table-tbody tr > td:nth-child(3) {
      ${compose(
        system({
          dividerMiddle: {
            property: `borderRight`,
          },
        }),
      )}
    }
    & .ant-table-tbody tr > td {
      vertical-align: middle;
      ${compose(
        system({
          backgroundColor: {
            property: `backgroundColor`,
            scale: `colors`,
          },
          fontWeight: {
            property: `fontWeight`,
            scale: `fontWeights`,
          },
          fontSize: {
            property: `fontSize`,
            scale: `fontSizes`,
          },
          color: {
            property: `color`,
            scale: `colors`,
          },
          padding: {
            property: `padding`,
          },
        }),
      )}
    }
    & .ant-table-tbody tr:nth-child(even) {
      ${compose(
        system({
          oddBackgroundColor: {
            property: `backgroundColor`,
            scale: `colors`,
          },
        }),
      )}
    }
    & .ant-table {
      ${compose(
        system({
          border: {
            property: `border`,
          },
          borderColor: {
            property: `borderColor`,
            scale: `colors`,
          },
        }),
      )}
    }
  `
  Table.defaultProps = {
    headerColor: `primary.2`,
    headerWeight: `normal`,
    headerBackgroundColor: `gray.0`,
    oddBackgroundColor: `gray.0`,
    fontWeight: `normal`,
    fontSize: `16px`,
    headerFontSize: `20px`,
    padding: `4px`,
    border: `1px solid`,
    borderColor: `gray.0`,
    borderWidth: `1px`,
    lastRowWeight: `1`,
    dividerMiddle: `none`,
  }
  return <Table {...props} />
}

export default TableWrapper
