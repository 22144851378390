import { MouseEventHandler } from 'react'
import { FC } from 'react'
import {
  MdAccessible as accessible,
  MdPregnantWoman as pregnantWoman,
  MdElderly as elderly,
  MdBlockFlipped as blockFlipped,
  MdExpand as expand,
  MdCompress as compress,
  MdModeEdit as modeEdit,
  MdRemoveRedEye as removeRedEye,
  MdDelete as deleteIcon,
  MdRotateRight as rotateRight,
  MdAddCircle as addCircle,
  MdHelpOutline as helpOutline,
} from 'react-icons/md'
import { FaBaby as baby } from 'react-icons/fa'
import styled from 'styled-components'
import {
  compose,
  color,
  typography,
  space,
  position,
  border,
  SpaceProps,
  BorderProps,
  PositionProps,
  TypographyProps,
  ColorProps,
} from 'styled-system'

const icons = {
  accessible,
  pregnantWoman,
  elderly,
  blockFlipped,
  expand,
  compress,
  baby,
  modeEdit,
  removeRedEye,
  deleteIcon,
  rotateRight,
  addCircle,
  helpOutline,
} as const

type IconsOptions = keyof typeof icons

type IIconWrapper = IconProps & {
  icon: IconsOptions
}

interface IconProps
  extends SpaceProps,
    BorderProps,
    PositionProps,
    TypographyProps,
    ColorProps {
  onClick?: MouseEventHandler<SVGElement>
  cursor?: string
}

function IconWrapper({ icon, ...props }: IIconWrapper): JSX.Element {
  const Component = icons[icon]
  if (!Component) {
    return null
  }
  const Icon: FC<IconProps> = styled(Component)<IconProps>`
    ${compose(color, typography, space, position, border)}
    ${({ onClick = null, cursor = `pointer` }) =>
      `${onClick != null ? `cursor: ${cursor}` : ``}`}
  `
  return <Icon {...props} />
}

export default IconWrapper
