import { FC } from 'react'
import styled from 'styled-components'
import {
  compose,
  background,
  space,
  layout,
  color,
  border,
  typography,
  BackgroundProps,
  TypographyProps,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
} from 'styled-system'
import AntdSelect, { SelectProps } from 'antd/es/select'
import Container from '../Container'
import Typography from '../Typography'

interface BaseSelectProps
  extends Omit<SelectProps, `size` | `onChange` | `value`>,
    BackgroundProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    TypographyProps {
  onChange(value: number | number[], name?: string): void
  name?: string
  value: number | number[] | null
}

const Select: FC<BaseSelectProps> = styled(AntdSelect)<BaseSelectProps>`
  width: 100%;
  & .ant-select-selection.ant-select-selection--single,
  & .ant-select-selection.ant-select-selection--multiple {
    width: 100%;
    border-radius: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    ${compose(background, layout, space, color, border, typography)}
    & .ant-select-selection__placeholder {
      ${color}
    }
  }
`

interface InputProps extends BaseSelectProps, FlexboxProps {
  error?: string
  label?: string
  disabled?: boolean
  options: Array<Record<string, string | number>>
  labelKey?: string
  valueKey?: string
  nullKey?: boolean
}

function InputWrapper(
  props: InputProps & {
    value: number | null
    onChange(value: number, name?: string): void
    mode?: undefined
  },
): JSX.Element
function InputWrapper(
  props: InputProps & {
    value: number[] | null
    onChange(value: number[], name?: string): void
    mode: `multiple` | `tags`
  },
): JSX.Element
function InputWrapper({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  placeholder,
  options = [],
  name,
  labelKey = `label`,
  valueKey = `value`,
  nullKey = false,
  value,
  onChange,
  mode = null,
  ...props
}: InputProps & {
  value: number | number[] | null
  onChange(value: number | number[], name?: string): void
  mode?: `multiple` | `tags`
}): JSX.Element {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <Select
          onChange={(value) => onChange(value, name)}
          placeholder={placeholder || `Seleccionar`}
          name={name}
          value={value === null && !nullKey ? undefined : value}
          key={name}
          mode={mode}
          {...props}
        >
          {options.map((option) => (
            <AntdSelect.Option key={option[valueKey]} value={option[valueKey]}>
              {option[labelKey]}
            </AntdSelect.Option>
          ))}
        </Select>
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
