import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import typography from './typography'
import palette from './palette'
import { useCallback, useEffect, useMemo, useState } from 'react'

export default function ThemeConfig({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const [xAxisUnit, setXAxisUnit] = useState(`vh`)
  const [yAxisUnit, setYAxisUnit] = useState(`vw`)
  const [isVertical, setIsVertical] = useState(false)

  const themeOptions = {
    typography,
    palette,
    responsive: {
      xAxisUnit,
      yAxisUnit,
      isVertical,
    },
  }

  const handleOrientationChange = useCallback(() => {
    const isVertical = window.innerHeight > window.innerWidth
    setXAxisUnit(isVertical ? `vh` : `vw`)
    setYAxisUnit(isVertical ? `vw` : `vh`)
    setIsVertical(isVertical)
  }, [])

  useEffect(() => {
    handleOrientationChange()

    window.addEventListener(`resize`, handleOrientationChange)

    return () => {
      window.removeEventListener(`resize`, handleOrientationChange)
    }
  }, [])

  const theme = useMemo(() => {
    const theme = createTheme(themeOptions)
    return theme
  }, [themeOptions])

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
