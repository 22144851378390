// TODO: deprecar al cambiar vistas
import styled from 'styled-components'
import { system } from 'styled-system'
import AntSwitch from 'antd/es/switch'
import 'antd/es/switch/style/index.css'

const enabledColor = system({
  enabledColor: {
    property: `backgroundColor`,
    scale: `colors`,
  },
})

const disabledColor = system({
  disabledColor: {
    property: `backgroundColor`,
    scale: `colors`,
  },
})

const Switch = styled(AntSwitch)`
  &.ant-switch {
    ${disabledColor}
  }
  &.ant-switch-checked.ant-switch {
    ${enabledColor}
  }
`
export default Switch
