import API from 'config/api'
import { format } from 'rut.js'
import { notification } from 'antd'
import moment from 'moment'

const csvHeaders = [
  { label: `Id`, key: `id` },
  { label: `Fila`, key: `line` },
  { label: `Fecha de Reserva`, key: `reservationDate` },
  { label: `Fecha de Solicitud`, key: `updatedAt` },
  { label: `Fecha de Ejecucion`, key: `executedAt` },
  { label: `RUT Paciente`, key: `patientRut` },
  { label: `Nombre Paciente`, key: `patientFirstName` },
  { label: `Apellido Paciente`, key: `patientLastName` },
  { label: `Mail Paciente`, key: `patientMail` },
  { label: `Número de Contacto`, key: `patientPhone` },
  { label: `Estado`, key: `status` },
  { label: `Campo Libre 1`, key: `customField1` },
  { label: `Campo Libre 2`, key: `customField2` },
  { label: `Selección Libre`, key: `customCheckbox` },
]

const optionsByStatus = [
  { value: 3, label: `Confirmadas` },
  { value: 2, label: `Tomadas` },
  { value: 4, label: `Anuladas` },
  { value: 6, label: `Canceladas` },
  { value: 5, label: `Ejecutadas` },
  { value: 7, label: `Ausentes` },
  { value: 0, label: `Todas` },
]

const formatCSVData = (data) => {
  const formattedData = data.map(
    ({
      patientRut,
      patientRutDv,
      patientPhone,
      status,
      customCheckbox,
      line,
      reservationDate,
      ticket,
      executedAt,
      updatedAt,
      ...reservation
    }) => {
      let executionDate = executedAt
      if (!executedAt && ticket) {
        executionDate = ticket.createdAt
      }
      return {
        ...reservation,
        patientRut: format(`${patientRut.toString()}-${patientRutDv}`),
        patientPhone: `+56 ${patientPhone}`,
        status: optionsByStatus.some((option) => option.value === status)
          ? optionsByStatus.find((option) => option.value === status).label
          : `Desconocido`,
        customCheckbox: customCheckbox ? `Sí` : `No`,
        line: line ? line.name : `-`,
        reservationDate: moment(reservationDate).format(`DD/MM/YYYY HH:mm:ss`),
        executedAt: executionDate
          ? moment(executionDate).format(`DD/MM/YYYY HH:mm:ss`)
          : `Sin fecha`,
        updatedAt: moment(updatedAt).format(`DD/MM/YYYY HH:mm:ss`),
      }
    },
  )
  return formattedData
}

export async function getCSVReservations({
  setState,
  selectedUnit,
  selectedLine,
  statusToShow,
  dateRange,
  extraFilters = {},
  errorInEmpty = false,
}) {
  if (!selectedUnit) {
    return
  }
  setState({ loadingCSV: true })
  try {
    const { reservations } = await API.getReservations({
      unitId: selectedUnit,
      lineId: selectedLine,
      extraFilters: { status: statusToShow, ...extraFilters },
      dateRange,
    })
    if (errorInEmpty && reservations.length == 0) {
      notification.error({
        message: `No se encontraron reservas para descargar`,
      })
    }
    setState({
      csvData: formatCSVData(reservations),
      csvHeaders,
      loadingCSV: false,
      error: null,
    })
  } catch (e) {
    const error =
      typeof e === `string` ? e : `Error al obtener datos descargables`
    setState({
      error,
      loadingCSV: false,
    })
  }
}
