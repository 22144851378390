import { useEffect, useState } from 'react'
import useDebounce from './useDebounce'
import useStateWithMerge from './useStateWithMerge'

const isEmptyObj = (obj) => Object.keys(obj).length === 0

const getInitialState = (defaultPageSize) => {
  const pageSize = defaultPageSize == null ? 10 : defaultPageSize
  return {
    pagination: { page: 1, pageSize },
    order: [],
    actualOrder: {},
  }
}

function useTableManager({
  onSearch,
  filterText,
  initialColumns = [],
  searchOnChange = [],
  extraFilters = {},
  rebuildColumnsOnChange = [],
  withPolling = false,
  defaultPageSize,
}) {
  const [state, setState] = useStateWithMerge(getInitialState(defaultPageSize))
  const [columns, setColumns] = useState([])
  const debouncedSearchItem = useDebounce(filterText, 2000)
  const { pagination, order, actualOrder } = state

  // Función para gatillar una búsqueda
  // puede usarse para un retry
  function retrySearch() {
    if (!withPolling) {
      setState(getInitialState(defaultPageSize))
    }
    onSearch({
      pagination,
      order,
      filter: debouncedSearchItem,
      extraFilters,
    })
  }

  // Los filtros de las tablas no los solemos usar (2do parametro)
  function onTableChange(pagination, filtersTable, sorter) {
    const directions = {
      ascend: `ASC`,
      descend: `DESC`,
    }
    let field = null
    if (!isEmptyObj(sorter)) {
      field = sorter.column ? sorter.column.sorterPath : sorter.field
      if (field && sorter.order != null) {
        setState({
          order: [[...field.split(`.`), directions[sorter.order]]],
          actualOrder: sorter,
        })
      } else {
        setState({
          order: [],
          actualOrder: {},
        })
      }
    }
    if (!isEmptyObj(pagination)) {
      setState({
        pagination: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })
    }
    if (!withPolling) {
      onSearch({
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        order: field
          ? [[...field.split(`.`), directions[sorter.order]]]
          : undefined,
        filter: debouncedSearchItem,
        extraFilters,
      })
    }
  }

  useEffect(() => {
    //Se reinicia la paginación y orders
    if (!withPolling) {
      retrySearch()
    }
  }, [debouncedSearchItem, ...searchOnChange, ...Object.values(extraFilters)])

  //  Se mapean las columnas cada vez que sortea una columna
  // eslint-disable-next-line max-len
  //  Es OBLIGATORIO que se defina un key en la columna para que funcione apropiadamente
  useEffect(() => {
    const columns = initialColumns.map((column) => {
      const extraProps = {}
      if (column.sorter) {
        extraProps.sortOrder =
          actualOrder.columnKey === column.key && actualOrder.order
      }
      return {
        ...column,
        ...extraProps,
      }
    })
    setColumns(columns)
  }, [actualOrder, ...rebuildColumnsOnChange])

  return {
    onTableChange,
    pagination,
    columns,
    retrySearch,
    debouncedSearchItem,
    order,
  }
}

export default useTableManager
