import { PaletteColorOptions } from '@mui/material'

// eslint-disable-next-line quotes
declare module '@mui/material/styles' {
  interface Tags {
    green: string
    blue: string
    red: string
    yellow: string
  }

  interface PaletteColor {
    darker?: string
  }

  interface SimplePaletteColorOptions {
    darker?: string
  }

  interface Palette {
    primary: PaletteColor
    secondary: PaletteColor
    error: PaletteColor
    warning: PaletteColor
    info: PaletteColor
    success: PaletteColor
    tertiary: string[]
    gray: string[]
    errorContainer: string[]
    hintContainer: string[]
    successContainer: string[]
    warningContainer: string[]
    tags: Tags
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions
    secondary?: PaletteColorOptions
    error?: PaletteColorOptions
    warning?: PaletteColorOptions
    info?: PaletteColorOptions
    success?: PaletteColorOptions
    tertiary?: string[]
    gray?: string[]
    errorContainer?: string[]
    hintContainer?: string[]
    successContainer?: string[]
    warningContainer?: string[]
    tags?: Tags
  }
}

const blueColors = {
  primary: {
    darker: `#192542`,
    dark: `#004266`,
    main: `#1890FF`,
    light: `#88C8FB`,
  },
  secondary: {
    main: `#00C1D0`,
  },
}

const redColors = {
  primary: {
    darker: `#231942`,
    dark: `#5E548E`,
    main: `#9F86C0`,
    light: `#E0B1CB`,
  },
  secondary: {
    main: `#2B2D42`,
  },
}

const others = {
  //default theme colors
  error: {
    main: `#FF4D4F`,
  },
  warning: {
    main: `#F5A723`,
  },
  info: {
    main: `#129DC8`,
  },
  success: {
    main: `#328A0E`,
  },
  //custom colors
  tertiary: [`#52C41A`, `#faad14`, `#FF4D4F`, `#5CDBD3`],
  gray: [`#EEEDEC`, `#C0BFC0`, `#9EA2A8`, `#4A4A4A`, `#F5F5F5`],
  errorContainer: [`#FFA39E`, `#FFF1F0`],
  hintContainer: [`#91D5FF`, `#E6F7FF`],
  successContainer: [`#B7EB8F`, `#F6FFED`],
  warningContainer: [`#FFE58F`, `#FFFBE6`],
  tags: {
    green: `#52C41A`,
    blue: `#1890FF`,
    red: `#FF4D4F`,
    yellow: `#FAAD14`,
  },
  primaryTotem: {
    main: `#1565C0`,
    light: `#4383CC`,
    dark: `#0E4686`,
  },
  secondaryTotem: {
    main: `#E6007E`,
    light: `#EB3397`,
    dark: `#A10058`,
  },
  errorTotem: {
    main: `#F44336`,
    light: `#F6685E`,
    dark: `#AA2E25`,
  },
  warningTotem: {
    main: `#FF9800`,
    light: `#FFAC33`,
    dark: `#B26A00`,
  },
  successTotem: {
    main: `#4CAF50`,
    light: `#6FBF73`,
    dark: `#357A38`,
  },
  infoTotem: {
    main: `#007DFF`,
    light: `#3397FF`,
    dark: `#0057B2`,
  },
  whiteTotem: {
    main: `#FFFFFF`,
  },
}

const defaultThemeKey = process.env.REACT_APP_DEFAULT_THEME || `red`
const defaultColors = { red: redColors, blue: blueColors }

const palette = {
  ...defaultColors[defaultThemeKey],
  ...others,
  common: {
    black: `#000000`,
    white: `#ffffff`,
  },
}

export default palette
