import { useState } from 'react'
import ReactQuill from 'react-quill'
import HTMLParser from 'html-react-parser'
import Container from './Container'
import Typography from './Typography'
import RichTextWrapper from './RichTextWrapper'
import Input from './Input'
import Icon from './old/Icon'
import ColorPicker from './ColorPicker'
import 'react-quill/dist/quill.snow.css'

const colorOptions = [`#328A0E`, `#F5A723`, `#D0021B`, `#129DC8`]
function AlertPicker({
  publicConfig = {},
  onChange,
  label = `Mensaje de alerta en portal público`,
  isClosableInput = true,
}) {
  const { text, isActive, color, isClosable } = publicConfig
  return (
    <Container marginY="2" width="100%" flexDirection="column">
      <Input
        flexDirection="row"
        type="switch"
        name="isActive"
        value={isActive}
        label={label}
        onChange={onChange}
      />
      {isActive && (
        <>
          <Container
            marginY="2"
            justifyContent="space-between"
            width="100%"
            alignItems="flex-start"
          >
            <ReactQuill
              theme="snow"
              value={text || ``}
              onChange={(value) => {
                onChange(value, `text`)
              }}
            />
            {isClosableInput ? (
              <Input
                type="switch"
                label="Cerrable:"
                name="isClosable"
                value={isClosable}
                onChange={(value, name) => onChange(value, name)}
              />
            ) : null}

            <ColorPickerSection onChange={onChange} alertColor={color} />
          </Container>
          <Typography fontStyle="italic" marginY="2">
            Previsualización
          </Typography>
          <AlertPreview
            color={color}
            text={text}
            isClosable={isClosable}
            isClosableInput={isClosableInput}
          />
        </>
      )}
    </Container>
  )
}

function ColorPickerSection({ onChange, alertColor }) {
  const [showPicker, setShowPicker] = useState(false)
  const [pickerColorProps, setPickerColorProps] = useState({
    hex: alertColor || `primary.0`,
  })

  function handleChange(props) {
    setPickerColorProps(props)
  }

  function handleChangeCompleted(props) {
    onChange(props.hex, `color`)
  }

  return (
    <Container justifyContent="space-between" width="20%">
      <Typography marginBottom="1" fontWeight="3" color="gray.3" width="100%">
        Tipo de alerta:
      </Typography>
      {colorOptions.map((color) => (
        <Container
          key={color}
          width="2em"
          height="2em"
          padding="3"
          borderRadius="50%"
          backgroundColor={color}
          justifyContent="center"
          alignContent="center"
          onClick={() => onChange(color, `color`)}
        >
          {alertColor === color && <Icon color="white" icon="check" />}
        </Container>
      ))}
      <Container
        width="2em"
        height="2em"
        padding="3"
        borderRadius="50%"
        backgroundColor={pickerColorProps.hex}
        justifyContent="center"
        alignContent="center"
        onClick={() => setShowPicker(!showPicker)}
      >
        {alertColor === pickerColorProps.hex && (
          <Icon icon="check" color="white" />
        )}
        {!showPicker && alertColor !== pickerColorProps.hex ? (
          <Icon icon="plus" color="white" />
        ) : null}
      </Container>
      {showPicker && (
        <ColorPicker
          onChange={handleChange}
          onChangeComplete={handleChangeCompleted}
          color={pickerColorProps.hex}
          disableAlpha
          {...pickerColorProps}
        />
      )}
    </Container>
  )
}

function AlertPreview({ isClosable, text, color, isClosableInput }) {
  return (
    <Container
      position="relative"
      padding="3"
      backgroundColor={color}
      width="100%"
      color="white"
      borderRadius="4px"
      withShadow
    >
      {isClosable && isClosableInput && (
        <Icon
          position="absolute"
          top="1"
          right="1"
          icon="timesCircle"
          color="white"
        />
      )}
      <RichTextWrapper>{text ? HTMLParser(text) : ``}</RichTextWrapper>
    </Container>
  )
}

export default AlertPicker
