import Calendar from 'react-big-calendar'
import moment from 'moment'
import { CalendarContainer } from '../styles'
import EventItem from './EventItem'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = Calendar.momentLocalizer(moment)

function mapData({
  appointments = [],
  appointmentsById,
  professionalsById,
  professionals = [],
  type,
  onClick,
}) {
  const events = []
  let maxDate = null
  let minDate = null
  appointments.forEach((id) => {
    const unformattedAppointment = appointmentsById[id]
    if (
      !maxDate ||
      moment(unformattedAppointment.reservationEnd).isAfter(moment(maxDate))
    ) {
      maxDate = unformattedAppointment.reservationEnd
    }
    if (
      !minDate ||
      moment(unformattedAppointment.reservationStart).isBefore(moment(minDate))
    ) {
      minDate = unformattedAppointment.reservationStart
    }

    const duration = moment(unformattedAppointment.reservationEnd).diff(
      unformattedAppointment.reservationStart,
      `minutes`,
    )
    const appointment = {
      ...unformattedAppointment,
      startTime: new Date(unformattedAppointment.reservationStart),
      endTime: new Date(unformattedAppointment.reservationEnd),
      resourceId: `${unformattedAppointment.scheduleUserId}`,
      fontSize: `${Math.min(18, 1.2 * duration)}px`,
      overbook: null,
    }
    if (appointment.appointmentTypeId !== 4) {
      events.push({
        ...appointment,
        onClick: (appointment) => onClick(appointment),
        overbooks: [],
      })
    } else {
      const index = events.findIndex(
        (eventItem) =>
          eventItem.scheduleUserId === appointment.scheduleUserId &&
          eventItem.reservationStart === appointment.reservationStart,
      )
      if (index >= 0) {
        events[index].overbooks =
          events[index].overbooks.length === 0
            ? [appointment]
            : [...events[index].overbooks, appointment]
      } else {
        events.push({
          ...appointment,
          onClick: (appointment) => onClick(appointment),
          overbooks: [],
        })
      }
    }
  })

  const resources = []
  if (type === `general`) {
    professionals.forEach((professionalId) => {
      resources.push({
        resourceId: `${professionalId}`,
        resourceTitle: `${professionalsById[professionalId].firstName} ${professionalsById[professionalId].lastName}`,
      })
    })
  }
  return { events, resources, minDate, maxDate }
}

function CalendarWrapper({ data, currentDate, type = `general`, onClick }) {
  const { events, resources, minDate, maxDate } = mapData({
    ...data,
    type,
    onClick,
  })
  return (
    <CalendarContainer type={type}>
      <Calendar
        localizer={localizer}
        events={events}
        date={currentDate._d}
        defaultView="day"
        views={[`day`]}
        toolbar={false}
        startAccessor="startTime"
        endAccessor="endTime"
        resources={type === `general` ? resources : null}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        components={{
          day: {
            event: EventItem,
          },
        }}
        scrollToTime={currentDate._d}
        min={
          minDate
            ? moment(minDate).set({ minute: 0, second: 0 })._d
            : moment(currentDate).set({ hour: 8, minute: 0, second: 0 })._d
        }
        max={
          maxDate
            ? moment(maxDate).add(1, `hours`).set({ minute: 0, second: 0 })._d
            : moment(currentDate).set({ hour: 19, minute: 0, second: 0 })._d
        }
      />
    </CalendarContainer>
  )
}

export default CalendarWrapper
