import { FC } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
  FlexboxProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system'
import AntdTimePicker, { TimePickerProps } from 'antd/es/time-picker'
import Container from '../Container'
import Typography from '../Typography'

const TimePicker: FC<TimePickerProps> = styled(AntdTimePicker)<TimePickerProps>`
  &.ant-time-picker {
    width: 100% !important;
  }
`

interface InputProps
  extends Omit<TimePickerProps, `onChange`>,
    FlexboxProps,
    ColorProps,
    SpaceProps,
    Omit<LayoutProps, `size`> {
  onChange(value: string, name?: string): void
  name?: string
  error?: string
  label?: string
}
const InputWrapper: FC<InputProps> = ({
  label,
  error = null,
  margin = null,
  color = null,
  width = null,
  flex = null,
  flexDirection = `column`,
  justifySelf = null,
  value,
  onChange,
  placeholder,
  name,
  allowClear = false,
  format = `HH:mm`,
  ...props
}) => {
  return (
    <>
      <Container
        justifySelf={justifySelf}
        flexDirection={flexDirection}
        margin={margin}
        width={width}
        flex={flex}
      >
        {label && (
          <Typography
            marginRight={flexDirection == `column` ? `0` : `2`}
            textStyle="colTable1"
            color={color}
          >
            {label}
          </Typography>
        )}
        <TimePicker
          value={value ? moment(value, format) : null}
          key={name}
          onChange={(date) => {
            onChange(date.format(format), name)
          }}
          format={format}
          placeholder={placeholder || `Seleccionar`}
          allowClear={allowClear}
          {...props}
        />
      </Container>
      {error && (
        <Typography fontWeight="100" margin="0" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}
export default InputWrapper
