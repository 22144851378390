import moment from 'moment'
import { notification } from 'antd'
import { removeIndexFromArray } from 'utils'
import Container from './Container'
import Input from './Input'
import Button from './Button'
import Typography from './Typography'
import Icon from './old/Icon'

const formatDay = (day) => {
  return moment().day(day).format(`dddd`).toUpperCase()
}

// Valida si la hora de inicio seleccionada es válida
// (hora termino anterior + periodo anterior <= hora de inicio nueva)
function isInitDateValid({ value, timeBlocks, index }) {
  if (index === 0) {
    return true
  }

  const { endTime: lastEndTime, period: lastPeriod } =
    index - 1 > -1 ? timeBlocks[index - 1] : {}

  const [selectedHour, selectedMinute] = value.split(`:`)
  const [lastBlockHour, lastBlockMinute] = lastEndTime.split(`:`)

  if (lastEndTime) {
    const validDateStart = moment()
      .set({
        hour: lastBlockHour,
        minute: lastBlockMinute,
        seconds: 0,
      })
      .add(lastPeriod || 0, `minutes`)

    const selectedDateStart = moment().set({
      hour: selectedHour,
      minute: selectedMinute,
      seconds: 0,
    })

    if (selectedDateStart.isSameOrAfter(validDateStart)) {
      return true
    }

    return false
  }

  return false
}

const DayTimeConfig = ({
  type = `schedule`,
  defaultPeriod = 15,
  defaultNewBlock,
  includePeriod = true,
  schedule,
  day,
  changeWeekConfig,
  disabled = false,
}) => {
  const isEditable = schedule.open && !disabled
  function removeBlock(index) {
    if (isEditable) {
      const nextBlocks = removeIndexFromArray(schedule.timeBlocks, index)
      if (nextBlocks.length === 0) {
        changeWeekConfig([day, `open`], false)
      }
      return changeWeekConfig([day, `timeBlocks`], nextBlocks)
    }
    return null
  }
  function addBlock() {
    if (isEditable) {
      let newBlock
      const timeBlocksNumber = schedule.timeBlocks.length
      if (timeBlocksNumber === 0) {
        newBlock = defaultNewBlock || {
          startTime: `07:00`,
          endTime: `18:00`,
        }
        if (includePeriod) {
          newBlock.period = defaultPeriod
        }
      } else {
        const { endTime: prevEndTime, period = defaultPeriod } =
          schedule.timeBlocks[timeBlocksNumber - 1]
        const startTime = moment(prevEndTime, `HH:mm`)
          .add(period * 2, `minutes`)
          .format(`HH:mm`)
        const endTime = moment(startTime, `HH:mm`)
          .add(period * 4, `minutes`)
          .format(`HH:mm`)
        newBlock = {
          startTime,
          endTime,
        }
        if (includePeriod) {
          newBlock.period = period
        }
      }

      changeWeekConfig([day, `timeBlocks`], [...schedule.timeBlocks, newBlock])
    }
  }
  return (
    <>
      <Container>
        <Input
          name="open"
          value={schedule.open}
          disabled={disabled}
          type="checkbox"
          onChange={(value) => changeWeekConfig([day, `open`], value)}
          margin="0px 16px 0px 0px"
        />
        <Typography textStyle="sideMenu">{formatDay(day)}</Typography>
      </Container>
      <Container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {schedule.timeBlocks.map((block, index) => (
          <>
            <Button
              display="flex"
              backgroundColor="transparent"
              alignItems="center"
              justifyContent="center"
              padding={1}
              color={isEditable ? `error` : `gray.1`}
              width="100%"
            >
              <Icon icon="minusCircle" fontSize="1" marginRight={2} />
              <Typography
                textStyle="general"
                borderBottom="1px solid"
                fontSize="1"
                onClick={() => removeBlock(index)}
              >
                Eliminar bloque
              </Typography>
            </Button>
            <Input
              name="startTime"
              value={block.startTime}
              disabled={!isEditable}
              period={block.period}
              type="time"
              onChange={(value, name) => {
                if (
                  isInitDateValid({
                    value,
                    timeBlocks: schedule.timeBlocks,
                    index,
                  })
                ) {
                  return changeWeekConfig(
                    [day, `timeBlocks`, index, name],
                    value,
                  )
                }
                return notification.error({
                  message: `La hora de inicio seleccionada no es válida`,
                })
              }}
              allowClear={false}
              margin="8px 0px"
            />
            <Input
              name="endTime"
              value={block.endTime}
              disabled={!isEditable}
              period={block.period}
              type="time"
              onChange={(value, name) =>
                changeWeekConfig([day, `timeBlocks`, index, name], value)
              }
              allowClear={false}
            />
            <Container flexDirection="row" width="100%">
              {type == `reservation` && (
                <Input
                  name="parallelReservations"
                  type="number"
                  value={
                    block.parallelReservations || schedule.parallelReservations
                  }
                  disabled={!isEditable}
                  addonBefore="p"
                  onChange={(value, name) =>
                    changeWeekConfig([day, `timeBlocks`, index, name], value)
                  }
                  margin="16px 5px 0 0"
                  width="48%"
                />
              )}
              {includePeriod && (
                <Input
                  name="period"
                  type="number"
                  value={block.period}
                  disabled={!isEditable}
                  addonBefore="f"
                  onChange={(value, name) =>
                    changeWeekConfig([day, `timeBlocks`, index, name], value)
                  }
                  margin="16px 0px"
                  width={type == `reservation` ? `48%` : `100%`}
                />
              )}
            </Container>
          </>
        ))}
        <Button
          display="flex"
          backgroundColor="transparent"
          alignItems="center"
          justifyContent="center"
          padding={1}
          color={isEditable ? `primary.3` : `gray.1`}
          width="100%"
        >
          <Icon icon="plusCircle" marginRight={2} />
          <Typography
            textStyle="general"
            borderBottom="1px solid"
            onClick={addBlock}
          >
            Crear horario
          </Typography>
        </Button>
      </Container>
    </>
  )
}

export default DayTimeConfig
