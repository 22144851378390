import React, { FC, forwardRef } from 'react'
import styled from 'styled-components'
import {
  compose,
  background,
  BackgroundProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  position,
  PositionProps,
  grid,
  GridProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
  variant,
} from 'styled-system'
import containerVariants, { Variants } from './variants'
import Icon from '../old/Icon'
import { setProperty, colorsWithOpacity, backgroundGradient } from 'utils'
interface ContainerStyleProps
  extends BackgroundProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    PositionProps,
    GridProps,
    TypographyProps,
    ColorProps {}
interface IContainerProps
  extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, `color`>,
    ContainerStyleProps {
  withShadow?: boolean
  ref?: React.ForwardedRef<HTMLDivElement>
  hoverProps?: ContainerStyleProps
  cursor?: string
  shadow?: string
  variant?: Variants
}

const Container: FC<IContainerProps> = styled.div<IContainerProps>`
  ${compose(
    background,
    space,
    layout,
    flexbox,
    border,
    position,
    grid,
    typography,
    color,
    colorsWithOpacity,
    backgroundGradient,
  )}
  ${({ theme }) =>
    variant({
      variants: containerVariants(theme),
    })}
  ${({ onClick = null, cursor = null, shadow = ``, withShadow = false }) => `
  ${onClick == null ? `` : `cursor: pointer`};
  ${setProperty(`cursor`, `${cursor} !important`)}
  ${
    shadow?.length > 0
      ? `-webkit-box-shadow: ${shadow}; box-shadow: ${shadow};`
      : ``
  }
  ${withShadow ? `box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); !important;` : ``}
  `}
  transition: all .5s ease;
  &:hover {
    ${({ hoverProps, ...props }) =>
      compose(
        layout,
        space,
        flexbox,
        border,
        colorsWithOpacity,
      )({ ...props, ...hoverProps })}
  }
`
Container.defaultProps = {
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  maxWidth: `100%`,
}

type LoadingType = `NORMAL` | `OVERLAY`
export type ContainerProps = IContainerProps & {
  isLoading?: boolean
  loaderSize?: TypographyProps[`fontSize`]
  loaderColor?: ColorProps[`color`]
  loadingText?: string
  getLoadingType?: (types: Record<string, LoadingType>) => LoadingType
}
const loadingTypes: Record<string, LoadingType> = {
  normal: `NORMAL`,
  overlay: `OVERLAY`,
}
const ContainerWrapper = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      isLoading = false,
      loaderSize = `4`,
      loaderColor = `primary.0`,
      getLoadingType = () => loadingTypes.normal,
      loadingText = ` `,
      children,
      ...props
    },
    ref,
  ) => {
    if (isLoading) {
      const loadingType = getLoadingType(loadingTypes)
      if (loadingType === loadingTypes.normal) {
        return (
          <Container
            ref={ref}
            {...props}
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Icon
              icon="spinner"
              spin
              color={loaderColor}
              fontSize={loaderSize}
            />
            <Container marginLeft="15px" fontSize="20px">
              {loadingText}
            </Container>
          </Container>
        )
      } else if (loadingType === loadingTypes.overlay) {
        return (
          <Container
            ref={ref}
            {...props}
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            {children}
            <Container
              width="100%"
              height="100%"
              position="absolute"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              top={0}
              left={0}
              backgroundColor="gray.3@0.15"
            >
              <Icon
                icon="spinner"
                spin
                color={loaderColor}
                fontSize={loaderSize}
              />
              <Container marginLeft="15px" fontSize="20px">
                {loadingText}
              </Container>
            </Container>
          </Container>
        )
      }
    }
    return (
      <Container ref={ref} {...props}>
        {children}
      </Container>
    )
  },
)

ContainerWrapper.displayName = `Container `

export default ContainerWrapper
